/* main colors */
/* main fonts */
/* main breakpoint */
@import url(vendors/jquery.fancybox.css);
.embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video, .tab-content__item, .v-align > * {
  width: 100%; }

.embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video, .tab-content__item, .v-align, .v-align:before {
  height: 100%; }

.v-align:before {
  width: 0; }

/* display */
.v-align:before, .v-align > * {
  display: inline-block; }

/* position */
.embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video, .tab-content__item {
  position: absolute; }

.embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video, .tab-content__item {
  top: 0;
  left: 0; }

.section--bg-img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover; }

/* float */
/* text align */
/* vertical align */
.v-align:before, .v-align > * {
  vertical-align: middle; }

/*-- 
    Margin & Padding
-----------------------------------------*/
/*
	This mixin can be used to set the object-fit:
	@include object-fit(contain);

	or object-fit and object-position:
	@include object-fit(cover, top);
*/
/* .box { @include transition(width, height 0.3s ease-in-out); } */
/* .box { @include border-radius(10px); } */
/* CSS3 calc() function to perform calculations */
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.5rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.5rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important; }

.m-2 {
  margin: 1rem !important; }

.mt-2,
.my-2 {
  margin-top: 1rem !important; }

.mr-2,
.mx-2 {
  margin-right: 1rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1rem !important; }

.m-3 {
  margin: 1.5rem !important; }

.mt-3,
.my-3 {
  margin-top: 1.5rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important; }

.m-4 {
  margin: 2rem !important; }

.mt-4,
.my-4 {
  margin-top: 2rem !important; }

.mr-4,
.mx-4 {
  margin-right: 2rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 2rem !important; }

.ml-4,
.mx-4 {
  margin-left: 2rem !important; }

.m-5 {
  margin: 2.5rem !important; }

.mt-5,
.my-5 {
  margin-top: 2.5rem !important; }

.mr-5,
.mx-5 {
  margin-right: 2.5rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 2.5rem !important; }

.ml-5,
.mx-5 {
  margin-left: 2.5rem !important; }

.m-6 {
  margin: 3rem !important; }

.mt-6,
.my-6 {
  margin-top: 3rem !important; }

.mr-6,
.mx-6 {
  margin-right: 3rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 3rem !important; }

.ml-6,
.mx-6 {
  margin-left: 3rem !important; }

.m-7 {
  margin: 3.5rem !important; }

.mt-7,
.my-7 {
  margin-top: 3.5rem !important; }

.mr-7,
.mx-7 {
  margin-right: 3.5rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 3.5rem !important; }

.ml-7,
.mx-7 {
  margin-left: 3.5rem !important; }

.m-8 {
  margin: 4rem !important; }

.mt-8,
.my-8 {
  margin-top: 4rem !important; }

.mr-8,
.mx-8 {
  margin-right: 4rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 4rem !important; }

.ml-8,
.mx-8 {
  margin-left: 4rem !important; }

.m-9 {
  margin: 4.5rem !important; }

.mt-9,
.my-9 {
  margin-top: 4.5rem !important; }

.mr-9,
.mx-9 {
  margin-right: 4.5rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 4.5rem !important; }

.ml-9,
.mx-9 {
  margin-left: 4.5rem !important; }

.m-10 {
  margin: 5rem !important; }

.mt-10,
.my-10 {
  margin-top: 5rem !important; }

.mr-10,
.mx-10 {
  margin-right: 5rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 5rem !important; }

.ml-10,
.mx-10 {
  margin-left: 5rem !important; }

.m-11 {
  margin: 5.5rem !important; }

.mt-11,
.my-11 {
  margin-top: 5.5rem !important; }

.mr-11,
.mx-11 {
  margin-right: 5.5rem !important; }

.mb-11,
.my-11 {
  margin-bottom: 5.5rem !important; }

.ml-11,
.mx-11 {
  margin-left: 5.5rem !important; }

.m-12 {
  margin: 6rem !important; }

.mt-12,
.my-12 {
  margin-top: 6rem !important; }

.mr-12,
.mx-12 {
  margin-right: 6rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 6rem !important; }

.ml-12,
.mx-12 {
  margin-left: 6rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.5rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.5rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.5rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.5rem !important; }

.p-2 {
  padding: 1rem !important; }

.pt-2,
.py-2 {
  padding-top: 1rem !important; }

.pr-2,
.px-2 {
  padding-right: 1rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 1rem !important; }

.pl-2,
.px-2 {
  padding-left: 1rem !important; }

.p-3 {
  padding: 1.5rem !important; }

.pt-3,
.py-3 {
  padding-top: 1.5rem !important; }

.pr-3,
.px-3 {
  padding-right: 1.5rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important; }

.pl-3,
.px-3 {
  padding-left: 1.5rem !important; }

.p-4 {
  padding: 2rem !important; }

.pt-4,
.py-4 {
  padding-top: 2rem !important; }

.pr-4,
.px-4 {
  padding-right: 2rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 2rem !important; }

.pl-4,
.px-4 {
  padding-left: 2rem !important; }

.p-5 {
  padding: 2.5rem !important; }

.pt-5,
.py-5 {
  padding-top: 2.5rem !important; }

.pr-5,
.px-5 {
  padding-right: 2.5rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 2.5rem !important; }

.pl-5,
.px-5 {
  padding-left: 2.5rem !important; }

.p-6 {
  padding: 3rem !important; }

.pt-6,
.py-6 {
  padding-top: 3rem !important; }

.pr-6,
.px-6 {
  padding-right: 3rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 3rem !important; }

.pl-6,
.px-6 {
  padding-left: 3rem !important; }

.p-7 {
  padding: 3.5rem !important; }

.pt-7,
.py-7 {
  padding-top: 3.5rem !important; }

.pr-7,
.px-7 {
  padding-right: 3.5rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 3.5rem !important; }

.pl-7,
.px-7 {
  padding-left: 3.5rem !important; }

.p-8 {
  padding: 4rem !important; }

.pt-8,
.py-8 {
  padding-top: 4rem !important; }

.pr-8,
.px-8 {
  padding-right: 4rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 4rem !important; }

.pl-8,
.px-8 {
  padding-left: 4rem !important; }

.p-9 {
  padding: 4.5rem !important; }

.pt-9,
.py-9 {
  padding-top: 4.5rem !important; }

.pr-9,
.px-9 {
  padding-right: 4.5rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 4.5rem !important; }

.pl-9,
.px-9 {
  padding-left: 4.5rem !important; }

.p-10 {
  padding: 5rem !important; }

.pt-10,
.py-10 {
  padding-top: 5rem !important; }

.pr-10,
.px-10 {
  padding-right: 5rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 5rem !important; }

.pl-10,
.px-10 {
  padding-left: 5rem !important; }

.p-11 {
  padding: 5.5rem !important; }

.pt-11,
.py-11 {
  padding-top: 5.5rem !important; }

.pr-11,
.px-11 {
  padding-right: 5.5rem !important; }

.pb-11,
.py-11 {
  padding-bottom: 5.5rem !important; }

.pl-11,
.px-11 {
  padding-left: 5.5rem !important; }

.p-12 {
  padding: 6rem !important; }

.pt-12,
.py-12 {
  padding-top: 6rem !important; }

.pr-12,
.px-12 {
  padding-right: 6rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 6rem !important; }

.pl-12,
.px-12 {
  padding-left: 6rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.5rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem !important; }
  .m-sm-2 {
    margin: 1rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem !important; }
  .m-sm-3 {
    margin: 1.5rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem !important; }
  .m-sm-4 {
    margin: 2rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2rem !important; }
  .m-sm-5 {
    margin: 2.5rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2.5rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2.5rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2.5rem !important; }
  .m-sm-6 {
    margin: 3rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3rem !important; }
  .m-sm-7 {
    margin: 3.5rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3.5rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3.5rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3.5rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3.5rem !important; }
  .m-sm-8 {
    margin: 4rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 4rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 4rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 4rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 4rem !important; }
  .m-sm-9 {
    margin: 4.5rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4.5rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4.5rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4.5rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4.5rem !important; }
  .m-sm-10 {
    margin: 5rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 5rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 5rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 5rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 5rem !important; }
  .m-sm-11 {
    margin: 5.5rem !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 5.5rem !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 5.5rem !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 5.5rem !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 5.5rem !important; }
  .m-sm-12 {
    margin: 6rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 6rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 6rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 6rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 6rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.5rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem !important; }
  .p-sm-2 {
    padding: 1rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem !important; }
  .p-sm-3 {
    padding: 1.5rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.5rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.5rem !important; }
  .p-sm-4 {
    padding: 2rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2rem !important; }
  .p-sm-5 {
    padding: 2.5rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2.5rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2.5rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2.5rem !important; }
  .p-sm-6 {
    padding: 3rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3rem !important; }
  .p-sm-7 {
    padding: 3.5rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3.5rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3.5rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3.5rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3.5rem !important; }
  .p-sm-8 {
    padding: 4rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 4rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 4rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 4rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 4rem !important; }
  .p-sm-9 {
    padding: 4.5rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4.5rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4.5rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4.5rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4.5rem !important; }
  .p-sm-10 {
    padding: 5rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 5rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 5rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 5rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 5rem !important; }
  .p-sm-11 {
    padding: 5.5rem !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 5.5rem !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 5.5rem !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 5.5rem !important; }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 5.5rem !important; }
  .p-sm-12 {
    padding: 6rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 6rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 6rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 6rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 6rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.5rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important; }
  .m-md-2 {
    margin: 1rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important; }
  .m-md-3 {
    margin: 1.5rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem !important; }
  .m-md-4 {
    margin: 2rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2rem !important; }
  .m-md-5 {
    margin: 2.5rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2.5rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2.5rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2.5rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2.5rem !important; }
  .m-md-6 {
    margin: 3rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3rem !important; }
  .m-md-7 {
    margin: 3.5rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3.5rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3.5rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3.5rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3.5rem !important; }
  .m-md-8 {
    margin: 4rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 4rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 4rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 4rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 4rem !important; }
  .m-md-9 {
    margin: 4.5rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4.5rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4.5rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4.5rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4.5rem !important; }
  .m-md-10 {
    margin: 5rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 5rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 5rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 5rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 5rem !important; }
  .m-md-11 {
    margin: 5.5rem !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 5.5rem !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 5.5rem !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 5.5rem !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 5.5rem !important; }
  .m-md-12 {
    margin: 6rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 6rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 6rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 6rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 6rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.5rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important; }
  .p-md-2 {
    padding: 1rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important; }
  .p-md-3 {
    padding: 1.5rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem !important; }
  .p-md-4 {
    padding: 2rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2rem !important; }
  .p-md-5 {
    padding: 2.5rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2.5rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2.5rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2.5rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2.5rem !important; }
  .p-md-6 {
    padding: 3rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3rem !important; }
  .p-md-7 {
    padding: 3.5rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3.5rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3.5rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3.5rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3.5rem !important; }
  .p-md-8 {
    padding: 4rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 4rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 4rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 4rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 4rem !important; }
  .p-md-9 {
    padding: 4.5rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4.5rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4.5rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4.5rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4.5rem !important; }
  .p-md-10 {
    padding: 5rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 5rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 5rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 5rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 5rem !important; }
  .p-md-11 {
    padding: 5.5rem !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 5.5rem !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 5.5rem !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 5.5rem !important; }
  .pl-md-11,
  .px-md-11 {
    padding-left: 5.5rem !important; }
  .p-md-12 {
    padding: 6rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 6rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 6rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 6rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 6rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.5rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important; }
  .m-lg-2 {
    margin: 1rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important; }
  .m-lg-3 {
    margin: 1.5rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem !important; }
  .m-lg-4 {
    margin: 2rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2rem !important; }
  .m-lg-5 {
    margin: 2.5rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2.5rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2.5rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2.5rem !important; }
  .m-lg-6 {
    margin: 3rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3rem !important; }
  .m-lg-7 {
    margin: 3.5rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3.5rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3.5rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3.5rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3.5rem !important; }
  .m-lg-8 {
    margin: 4rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 4rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 4rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 4rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 4rem !important; }
  .m-lg-9 {
    margin: 4.5rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4.5rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4.5rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4.5rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4.5rem !important; }
  .m-lg-10 {
    margin: 5rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 5rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 5rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 5rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 5rem !important; }
  .m-lg-11 {
    margin: 5.5rem !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 5.5rem !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 5.5rem !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 5.5rem !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 5.5rem !important; }
  .m-lg-12 {
    margin: 6rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 6rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 6rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 6rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 6rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.5rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important; }
  .p-lg-2 {
    padding: 1rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important; }
  .p-lg-3 {
    padding: 1.5rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem !important; }
  .p-lg-4 {
    padding: 2rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2rem !important; }
  .p-lg-5 {
    padding: 2.5rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2.5rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2.5rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2.5rem !important; }
  .p-lg-6 {
    padding: 3rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3rem !important; }
  .p-lg-7 {
    padding: 3.5rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3.5rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3.5rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3.5rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3.5rem !important; }
  .p-lg-8 {
    padding: 4rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 4rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 4rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 4rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 4rem !important; }
  .p-lg-9 {
    padding: 4.5rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4.5rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4.5rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4.5rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4.5rem !important; }
  .p-lg-10 {
    padding: 5rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 5rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 5rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 5rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 5rem !important; }
  .p-lg-11 {
    padding: 5.5rem !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 5.5rem !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 5.5rem !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 5.5rem !important; }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 5.5rem !important; }
  .p-lg-12 {
    padding: 6rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 6rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 6rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 6rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 6rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.5rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important; }
  .m-xl-2 {
    margin: 1rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important; }
  .m-xl-3 {
    margin: 1.5rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem !important; }
  .m-xl-4 {
    margin: 2rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2rem !important; }
  .m-xl-5 {
    margin: 2.5rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2.5rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2.5rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2.5rem !important; }
  .m-xl-6 {
    margin: 3rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3rem !important; }
  .m-xl-7 {
    margin: 3.5rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3.5rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3.5rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3.5rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3.5rem !important; }
  .m-xl-8 {
    margin: 4rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 4rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 4rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 4rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 4rem !important; }
  .m-xl-9 {
    margin: 4.5rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4.5rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4.5rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4.5rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4.5rem !important; }
  .m-xl-10 {
    margin: 5rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 5rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 5rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 5rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 5rem !important; }
  .m-xl-11 {
    margin: 5.5rem !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 5.5rem !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 5.5rem !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 5.5rem !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 5.5rem !important; }
  .m-xl-12 {
    margin: 6rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 6rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 6rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 6rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 6rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.5rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important; }
  .p-xl-2 {
    padding: 1rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important; }
  .p-xl-3 {
    padding: 1.5rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem !important; }
  .p-xl-4 {
    padding: 2rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2rem !important; }
  .p-xl-5 {
    padding: 2.5rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2.5rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2.5rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2.5rem !important; }
  .p-xl-6 {
    padding: 3rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3rem !important; }
  .p-xl-7 {
    padding: 3.5rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3.5rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3.5rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3.5rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3.5rem !important; }
  .p-xl-8 {
    padding: 4rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 4rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 4rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 4rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 4rem !important; }
  .p-xl-9 {
    padding: 4.5rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4.5rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4.5rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4.5rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4.5rem !important; }
  .p-xl-10 {
    padding: 5rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 5rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 5rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 5rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 5rem !important; }
  .p-xl-11 {
    padding: 5.5rem !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 5.5rem !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 5.5rem !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 5.5rem !important; }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 5.5rem !important; }
  .p-xl-12 {
    padding: 6rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 6rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 6rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 6rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 6rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0); }

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0); }

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate3d(0, 0, 0); }

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0); }

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0); }

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0); }

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pull-1 {
  right: 8.33333%; }

.pull-2 {
  right: 16.66667%; }

.pull-3 {
  right: 25%; }

.pull-4 {
  right: 33.33333%; }

.pull-5 {
  right: 41.66667%; }

.pull-6 {
  right: 50%; }

.pull-7 {
  right: 58.33333%; }

.pull-8 {
  right: 66.66667%; }

.pull-9 {
  right: 75%; }

.pull-10 {
  right: 83.33333%; }

.pull-11 {
  right: 91.66667%; }

.pull-12 {
  right: 100%; }

.push-1 {
  left: 8.33333%; }

.push-2 {
  left: 16.66667%; }

.push-3 {
  left: 25%; }

.push-4 {
  left: 33.33333%; }

.push-5 {
  left: 41.66667%; }

.push-6 {
  left: 50%; }

.push-7 {
  left: 58.33333%; }

.push-8 {
  left: 66.66667%; }

.push-9 {
  left: 75%; }

.push-10 {
  left: 83.33333%; }

.push-11 {
  left: 91.66667%; }

.push-12 {
  left: 100%; }

@media (min-width: 576px) {
  .pull-sm-0 {
    right: auto; }
  .pull-sm-1 {
    right: 8.33333%; }
  .pull-sm-2 {
    right: 16.66667%; }
  .pull-sm-3 {
    right: 25%; }
  .pull-sm-4 {
    right: 33.33333%; }
  .pull-sm-5 {
    right: 41.66667%; }
  .pull-sm-6 {
    right: 50%; }
  .pull-sm-7 {
    right: 58.33333%; }
  .pull-sm-8 {
    right: 66.66667%; }
  .pull-sm-9 {
    right: 75%; }
  .pull-sm-10 {
    right: 83.33333%; }
  .pull-sm-11 {
    right: 91.66667%; }
  .pull-sm-12 {
    right: 100%; }
  .push-sm-0 {
    left: auto; }
  .push-sm-1 {
    left: 8.33333%; }
  .push-sm-2 {
    left: 16.66667%; }
  .push-sm-3 {
    left: 25%; }
  .push-sm-4 {
    left: 33.33333%; }
  .push-sm-5 {
    left: 41.66667%; }
  .push-sm-6 {
    left: 50%; }
  .push-sm-7 {
    left: 58.33333%; }
  .push-sm-8 {
    left: 66.66667%; }
  .push-sm-9 {
    left: 75%; }
  .push-sm-10 {
    left: 83.33333%; }
  .push-sm-11 {
    left: 91.66667%; }
  .push-sm-12 {
    left: 100%; } }

@media (min-width: 768px) {
  .pull-md-0 {
    right: auto; }
  .pull-md-1 {
    right: 8.33333%; }
  .pull-md-2 {
    right: 16.66667%; }
  .pull-md-3 {
    right: 25%; }
  .pull-md-4 {
    right: 33.33333%; }
  .pull-md-5 {
    right: 41.66667%; }
  .pull-md-6 {
    right: 50%; }
  .pull-md-7 {
    right: 58.33333%; }
  .pull-md-8 {
    right: 66.66667%; }
  .pull-md-9 {
    right: 75%; }
  .pull-md-10 {
    right: 83.33333%; }
  .pull-md-11 {
    right: 91.66667%; }
  .pull-md-12 {
    right: 100%; }
  .push-md-0 {
    left: auto; }
  .push-md-1 {
    left: 8.33333%; }
  .push-md-2 {
    left: 16.66667%; }
  .push-md-3 {
    left: 25%; }
  .push-md-4 {
    left: 33.33333%; }
  .push-md-5 {
    left: 41.66667%; }
  .push-md-6 {
    left: 50%; }
  .push-md-7 {
    left: 58.33333%; }
  .push-md-8 {
    left: 66.66667%; }
  .push-md-9 {
    left: 75%; }
  .push-md-10 {
    left: 83.33333%; }
  .push-md-11 {
    left: 91.66667%; }
  .push-md-12 {
    left: 100%; } }

@media (min-width: 992px) {
  .pull-lg-0 {
    right: auto; }
  .pull-lg-1 {
    right: 8.33333%; }
  .pull-lg-2 {
    right: 16.66667%; }
  .pull-lg-3 {
    right: 25%; }
  .pull-lg-4 {
    right: 33.33333%; }
  .pull-lg-5 {
    right: 41.66667%; }
  .pull-lg-6 {
    right: 50%; }
  .pull-lg-7 {
    right: 58.33333%; }
  .pull-lg-8 {
    right: 66.66667%; }
  .pull-lg-9 {
    right: 75%; }
  .pull-lg-10 {
    right: 83.33333%; }
  .pull-lg-11 {
    right: 91.66667%; }
  .pull-lg-12 {
    right: 100%; }
  .push-lg-0 {
    left: auto; }
  .push-lg-1 {
    left: 8.33333%; }
  .push-lg-2 {
    left: 16.66667%; }
  .push-lg-3 {
    left: 25%; }
  .push-lg-4 {
    left: 33.33333%; }
  .push-lg-5 {
    left: 41.66667%; }
  .push-lg-6 {
    left: 50%; }
  .push-lg-7 {
    left: 58.33333%; }
  .push-lg-8 {
    left: 66.66667%; }
  .push-lg-9 {
    left: 75%; }
  .push-lg-10 {
    left: 83.33333%; }
  .push-lg-11 {
    left: 91.66667%; }
  .push-lg-12 {
    left: 100%; } }

@media (min-width: 1200px) {
  .pull-xl-0 {
    right: auto; }
  .pull-xl-1 {
    right: 8.33333%; }
  .pull-xl-2 {
    right: 16.66667%; }
  .pull-xl-3 {
    right: 25%; }
  .pull-xl-4 {
    right: 33.33333%; }
  .pull-xl-5 {
    right: 41.66667%; }
  .pull-xl-6 {
    right: 50%; }
  .pull-xl-7 {
    right: 58.33333%; }
  .pull-xl-8 {
    right: 66.66667%; }
  .pull-xl-9 {
    right: 75%; }
  .pull-xl-10 {
    right: 83.33333%; }
  .pull-xl-11 {
    right: 91.66667%; }
  .pull-xl-12 {
    right: 100%; }
  .push-xl-0 {
    left: auto; }
  .push-xl-1 {
    left: 8.33333%; }
  .push-xl-2 {
    left: 16.66667%; }
  .push-xl-3 {
    left: 25%; }
  .push-xl-4 {
    left: 33.33333%; }
  .push-xl-5 {
    left: 41.66667%; }
  .push-xl-6 {
    left: 50%; }
  .push-xl-7 {
    left: 58.33333%; }
  .push-xl-8 {
    left: 66.66667%; }
  .push-xl-9 {
    left: 75%; }
  .push-xl-10 {
    left: 83.33333%; }
  .push-xl-11 {
    left: 91.66667%; }
  .push-xl-12 {
    left: 100%; } }

/* --------------------------------
   custom slick styles
-------------------------------- */
.slick-track {
  display: flex;
  align-items: flex-start; }

.slick-slide {
  flex-shrink: 0;
  outline: none; }
  .slick-slide > div:first-child {
    flex: 0 0 100%;
    width: 100%;
    height: 100%; }
  .slick-initialized .slick-slide {
    display: flex;
    height: auto; }

.slick-arrow {
  line-height: 1;
  text-align: center;
  cursor: pointer;
  z-index: 1; }
  .slick-arrow.slick-disabled {
    cursor: default; }

.slick-dots {
  line-height: 0;
  font-size: 0; }
  .slick-dots:last-child {
    margin-top: 45px; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: 15px; }
    .slick-dots li:first-child {
      margin-left: 0; }
    .slick-dots li.slick-active button {
      color: #2552e4;
      border-color: currentColor;
      cursor: default; }
      .slick-dots li.slick-active button:before {
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        background-color: currentColor; }
  .slick-dots button {
    position: relative;
    display: block;
    width: 15px;
    height: 15px;
    padding: 0;
    cursor: pointer;
    cursor: hand;
    color: transparent;
    border: 2px solid transparent;
    outline: none;
    background: transparent;
    border-radius: 50%; }
    .slick-dots button:before {
      content: "";
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      background-color: #e7eff7;
      border-radius: inherit; }
  .slick-dots--white li.slick-active button {
    color: #fff; }

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden; }
  #app > main {
    flex: 1 0 auto; }
  #app > footer {
    flex: 0 0 auto; }

iframe {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }

img {
  vertical-align: middle;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

.image-container {
  position: relative;
  display: inline-block; }

.lazy[src] {
  opacity: 0;
  will-change: opacity;
  transition: opacity 200ms; }
  @media screen and (prefers-reduced-motion: reduce) {
    .lazy[src] {
      transition: none; } }
  .lazy[src].loaded {
    opacity: 1; }

/* embed responsive */
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    border: 0; }
  .embed-responsive-21by9 {
    padding-top: 42.85714%; }
  .embed-responsive-16by9 {
    padding-top: 56.25%; }
  .embed-responsive-4by3 {
    padding-top: 75%; }
  .embed-responsive-1by1 {
    padding-top: 100%; }

.section {
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
  z-index: 0; }
  .section--no-pt {
    padding-top: 0 !important; }
  .section--no-pb {
    padding-bottom: 0 !important; }
  .section--light-blue-bg {
    background-color: #f9fbfc; }
  .section .spacer {
    flex: 0 0 100%;
    width: 100%;
    min-height: 1px; }
  .section .shape {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: -1px;
    overflow: hidden;
    z-index: -5; }
    .section .shape svg {
      vertical-align: middle;
      position: relative;
      bottom: 0;
      left: 50%;
      width: 100%;
      min-width: 1000px;
      height: auto;
      transform: translateX(-50%); }
  @media (min-width: 768px) {
    .section {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media (min-width: 992px) {
    .section {
      padding-top: 140px;
      padding-bottom: 140px; } }

/* parallax */
.jarallax {
  position: relative;
  z-index: 0; }
  .jarallax > .jarallax-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    font-family: "object-fit: cover";
    z-index: -1; }
  .desktop .jarallax {
    background-attachment: fixed; }

.v-align:before {
  content: "";
  margin-left: -4.5px; }

.small-container-with-btn {
  max-width: 750px !important; }

/* circled element */
.circled {
  border-radius: 50%; }

/* --------------------------------
   accordion
-------------------------------- */
.accordion-container {
  margin-top: 50px;
  margin-bottom: 50px; }
  .accordion-container:first-child {
    margin-top: 0; }
  .accordion-container:last-child {
    margin-bottom: 0; }

.accordion-item {
  border-top: 1px solid #e3e3e3; }
  .accordion-item:first-child .accordion-content {
    display: block; }
  .accordion-item.active .accordion-toggler {
    cursor: default; }
    .accordion-item.active .accordion-toggler i {
      color: #e0e0e0; }
      .accordion-item.active .accordion-toggler i:before, .accordion-item.active .accordion-toggler i:after {
        transform: rotate(-135deg); }

.accordion-toggler {
  position: relative;
  padding: 15px;
  padding-left: 0;
  padding-right: 40px;
  cursor: pointer; }
  .accordion-toggler i {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 34px;
    height: 34px;
    color: #2368fb;
    border: 2px solid currentColor;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out; }
    @media screen and (prefers-reduced-motion: reduce) {
      .accordion-toggler i {
        transition: none; } }
    .accordion-toggler i:before, .accordion-toggler i:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      border: 1px solid currentColor;
      transform-origin: center;
      transition: transform 0.4s ease-in-out; }
      @media screen and (prefers-reduced-motion: reduce) {
        .accordion-toggler i:before, .accordion-toggler i:after {
          transition: none; } }
    .accordion-toggler i:before {
      width: 2px;
      height: 12px;
      margin-left: -1px;
      margin-top: -6px; }
    .accordion-toggler i:after {
      width: 12px;
      height: 2px;
      margin-left: -6px;
      margin-top: -1px; }
  .accordion-toggler:hover i {
    color: #e0e0e0; }

.accordion-title {
  margin: 0; }

.accordion-content {
  display: none; }
  .accordion-content__inner {
    padding-bottom: 15px; }
  .accordion-content p {
    margin-top: 15px;
    margin-bottom: 15px; }

@media (min-width: 576px) {
  .accordion-toggler {
    padding: 30px 0;
    padding-right: 45px; }
  .accordion-content__inner {
    padding-bottom: 30px; } }

/* --------------------------------
   check list
-------------------------------- */
.check-list {
  line-height: 1.2;
  text-align: left; }
  .check-list li {
    margin-top: 20px;
    padding-left: 35px; }
    .check-list li:first-child {
      margin-top: 0; }
  .check-list .ico-checked, .check-list .ico-unchecked {
    float: left;
    margin-left: -35px;
    vertical-align: top; }

.ico-checked, .ico-unchecked {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  border-radius: 50%; }
  .ico-checked:before, .ico-unchecked:before {
    vertical-align: middle; }

.ico-checked {
  background-color: #11c313; }

.ico-unchecked {
  background-color: #e3306f; }

#why {
  background-color: #f3f3f3; }

#about-us {
  background-color: #f3f3f3 !important; }

#integration {
  background-color: #f3f3f3 !important; }

#consulting {
  background-color: #f3f3f3 !important; }

#contact-eyecatcher button {
  border-color: #1d2124 !important;
  background-color: #1d2124 !important;
  color: #fff !important; }

#contact-eyecatcher button:hover {
  border-color: #2a2c2f !important;
  background-color: #2a2c2f !important; }

#dashboard-company {
  background-color: #f3f3f3 !important; }

#dashboard-customer {
  background-color: #fff !important; }

#dashboard-features {
  background-color: #f3f3f3 !important; }

#cloud-on-premise {
  background-color: #f3f3f3 !important; }

#cloud-features {
  background-color: #f3f3f3 !important; }

#hardware-connectivity .__title {
  color: #2a2c2f; }

#hardware-features {
  background-color: #f3f3f3 !important; }

#hardware-why {
  background-color: #f3f3f3 !important; }

.drift-widget-chat-wrapper {
  min-height: 0 !important; }

.drift-widget-greeting-content {
  max-height: 100% !important; }

#api-why {
  background-color: #f3f3f3 !important; }

#api-features {
  background-color: #f3f3f3 !important; }

#career-about-us {
  background-color: #f3f3f3 !important; }

#career-offer .__item {
  border-radius: 20px !important; }

#contact {
  background-color: #1d2124; }
  #contact .__title {
    color: #fff; }
  #contact .slogan-txt {
    color: #fff;
    font-size: 19px; }
  #contact .__inner {
    color: #fff; }

/* --------------------------------
   comments list
-------------------------------- */
.comments-list {
  margin-top: 30px;
  margin-bottom: 30px; }
  .comments-list:first-child {
    margin-top: 0; }
  .comments-list:last-child {
    margin-bottom: 0; }
  .comments-list > .comment:first-child {
    margin-top: 0; }
  .comments-list .comment {
    margin-top: 50px;
    font-size: 1.6rem; }
    .comments-list .comment__author-img {
      width: 70px;
      margin-right: 20px;
      overflow: hidden;
      border-radius: 50%; }
    .comments-list .comment__author-name {
      display: block;
      line-height: 1;
      font-size: 1.6rem;
      font-family: "Quicksand", sans-serif;
      font-weight: 700;
      color: #333; }
  .comments-list ul {
    padding-left: 30px; }

@media (min-width: 992px) {
  .comments-list ul {
    padding-left: 50px; } }

@media (min-width: 1200px) {
  .comments-list ul {
    padding-left: 90px; } }

/* --------------------------------
   counters
-------------------------------- */
.counter {
  margin-top: 30px;
  margin-bottom: 30px; }
  .counter:first-child {
    margin-top: 0; }
  .counter:last-child {
    margin-bottom: 0; }
  .counter .__item {
    position: relative;
    width: 100%; }
  .counter .__ico {
    display: inline-block;
    vertical-align: top;
    line-height: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; }
    .counter .__ico > img,
    .counter .__ico > svg {
      display: block; }
    .counter .__ico + .__content {
      margin-top: 10px; }
  .counter .__content {
    line-height: 1;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    color: #333;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; }
  .counter .__count:before {
    pointer-events: none;
    display: block;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    content: attr(data-to) attr(data-after-text); }
  .counter .__count:after {
    content: attr(data-after-text); }

.counter--s1 .__inner {
  margin-bottom: -30px; }

.counter--s1 .__item {
  margin-bottom: 30px;
  padding: 25px;
  background-color: #fff; }
  .counter--s1 .__item--rounded {
    border-radius: 5px; }
  .counter--s1 .__item--shadow {
    box-shadow: 0px 0px 68px 0px rgba(174, 175, 175, 0.17); }

.counter--s1 .__ico {
  margin-right: 20px; }

.counter--s1 .__content {
  font-size: 1.6rem; }

.counter--s1 .__count {
  font-size: 4rem; }

.counter--s2 .__inner {
  margin-bottom: -50px; }

.counter--s2 .__item {
  margin-bottom: 50px; }

.counter--s2 .__content {
  font-size: 1.6rem; }

.counter--s2 .__count {
  font-size: 5rem;
  margin-bottom: 5px; }
  .counter--s2 .__count:last-child {
    margin-bottom: 0; }

.counter--s3 .__inner {
  margin-bottom: -50px; }

.counter--s3 .__item {
  margin-bottom: 50px; }

.counter--s3 .__content {
  font-size: 2rem; }

.counter--s3 .__count {
  position: relative;
  font-size: 8rem;
  color: #4d569b; }
  @media (min-width: 576px) {
    .counter--s3 .__count {
      font-size: 10rem; } }

/* --------------------------------
   icon box
-------------------------------- */
.icon-box {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 80px;
  height: 80px;
  background-color: #fff;
  border: 5px solid transparent;
  margin: auto;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }
  .icon-box--circled {
    border-radius: 50%; }
  .icon-box > img,
  .icon-box > svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto; }

/* --------------------------------
   pagination
-------------------------------- */
.pagination {
  flex-wrap: wrap;
  align-items: center;
  margin: -5px;
  border-radius: 0; }
  .pagination .page-item {
    margin: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; }
    .pagination .page-item.active .page-link {
      background-color: #2368fb;
      border-color: #2368fb;
      cursor: default; }
    .pagination .page-item > span {
      display: inline-block;
      vertical-align: top;
      line-height: 1; }
  .pagination .page-link {
    width: 44px;
    height: 44px;
    margin-left: 0;
    padding: 0;
    line-height: 42px;
    font-size: 1.2rem;
    color: #888;
    text-align: center;
    text-decoration: none;
    border-color: #eee;
    border-radius: 50% !important;
    box-shadow: none;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out; }
    @media screen and (prefers-reduced-motion: reduce) {
      .pagination .page-link {
        transition: none; } }
    .pagination .page-link i {
      line-height: 1;
      font-size: 1.6rem; }
      .pagination .page-link i:before {
        vertical-align: middle; }

@media (min-width: 576px) {
  .pagination {
    margin: -5px -10px; }
    .pagination .page-item {
      margin: 5px 10px; } }

/* --------------------------------
   share buttons
-------------------------------- */
.share-btns__list {
  margin: -5px;
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px; }

.share-btns li {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  line-height: 1.1; }

.share-btns a {
  display: block;
  padding: 12px 25px;
  font-size: 1.8rem;
  color: #fff;
  text-decoration: none;
  letter-spacing: 0;
  border-radius: 30px;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, color 0.25s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .share-btns a {
      transition: none; } }

.share-btns .fb {
  background-color: #3c5a9a; }
  .share-btns .fb:hover, .share-btns .fb:focus {
    background-color: #31497d; }

.share-btns .tw {
  background-color: #1da1f2; }
  .share-btns .tw:hover, .share-btns .tw:focus {
    background-color: #0d8cda; }

.share-btns .yt {
  background-color: #f11819; }
  .share-btns .yt:hover, .share-btns .yt:focus {
    background-color: #d30d0e; }

.share-btns .in {
  background-image: -moz-linear-gradient(155deg, #f4a961 13%, #c32869 54%, #7324c1 100%);
  background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #7324c1), color-stop(46%, #c32869), color-stop(87%, #f4a961));
  background-image: -webkit-linear-gradient(155deg, #f4a961 13%, #c32869 54%, #7324c1 100%);
  background-image: -o-linear-gradient(155deg, #f4a961 13%, #c32869 54%, #7324c1 100%);
  background-image: -ms-linear-gradient(155deg, #f4a961 13%, #c32869 54%, #7324c1 100%);
  background-image: linear-gradient(295deg, #f4a961 13%, #c32869 54%, #7324c1 100%); }

.share-btns [class*=" fontello-"],
.share-btns [class^=fontello-] {
  display: inline-block;
  width: 1em;
  margin-right: 10px; }

/* --------------------------------
   social buttons
-------------------------------- */
.s-btns ul {
  margin-top: -10px;
  margin-left: -10px;
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px; }

.s-btns li {
  margin-top: 10px;
  margin-left: 10px; }

.s-btns a {
  display: block;
  letter-spacing: 0;
  text-align: center;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .s-btns a {
      transition: none; } }
  .s-btns a i {
    vertical-align: middle;
    width: 1em;
    line-height: 1; }

.s-btns--sm a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 1.7rem; }

.s-btns--md a {
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 2rem; }

.s-btns--light a {
  background-color: #fff;
  color: #313e4c; }
  .s-btns--light a:hover {
    background-color: rgba(255, 255, 255, 0.5); }

.s-btns--dark a {
  background-color: #2d3a49;
  color: #fff; }
  .s-btns--dark a:hover {
    background-color: rgba(45, 58, 73, 0.5); }

.s-btns--colored a {
  color: #fff; }

.s-btns--colored .f {
  background-color: #3c5a9a; }
  .s-btns--colored .f:hover, .s-btns--colored .f:focus {
    background-color: #31497d; }

.s-btns--colored .g {
  background-color: #f34a38; }
  .s-btns--colored .g:hover, .s-btns--colored .g:focus {
    background-color: #f12712; }

.s-btns--colored .t {
  background-color: #1da1f2; }
  .s-btns--colored .t:hover, .s-btns--colored .t:focus {
    background-color: #0d8cda; }

.s-btns--colored .y {
  background-color: #f11819; }
  .s-btns--colored .y:hover, .s-btns--colored .y:focus {
    background-color: #d30d0e; }

.s-btns--colored .i {
  background-image: -moz-linear-gradient(90deg, #db8c40 0%, #c32869 48%, #7324c1 100%);
  background-image: -ms-linear-gradient(90deg, #db8c40 0%, #c32869 48%, #7324c1 100%);
  background-image: -webkit-linear-gradient(90deg, #db8c40 0%, #c32869 48%, #7324c1 100%); }

.s-btns--rounded a {
  border-radius: 50%; }

/* --------------------------------
   store buttons
-------------------------------- */
.store-btns ul {
  margin-top: -15px;
  margin-left: -15px;
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px; }

.store-btns li {
  margin-top: 15px;
  margin-left: 15px; }

.store-btns a {
  display: inline-block;
  vertical-align: middle;
  padding: 14px 20px;
  line-height: 1.1;
  font-size: 1.6rem;
  letter-spacing: 0;
  text-decoration: none;
  text-shadow: none;
  border-radius: 30px;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .store-btns a {
      transition: none; } }
  .store-btns a > * {
    display: inline-block;
    vertical-align: middle; }
  .store-btns a svg {
    transition: fill 0.3s ease-in-out; }
    @media screen and (prefers-reduced-motion: reduce) {
      .store-btns a svg {
        transition: none; } }
  .store-btns a span {
    padding-left: 5px; }
    .store-btns a span:first-child {
      padding-left: 0; }

.store-btns--light a {
  background-color: #fff;
  color: #145595; }
  .store-btns--light a svg {
    fill: #28baff; }
  .store-btns--light a:hover {
    background-color: #333;
    color: #fff; }
    .store-btns--light a:hover svg {
      fill: currentColor; }

.store-btns--dark a {
  background-color: #333;
  color: #fff; }
  .store-btns--dark a svg {
    fill: currentColor; }
  .store-btns--dark a:hover {
    background-color: #484848; }

/* --------------------------------
   tab
-------------------------------- */
.tab-container {
  position: relative; }

.tab-nav {
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px; }
  .tab-nav__item {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    cursor: pointer;
    transition: all 0.3s ease-in-out; }
    @media screen and (prefers-reduced-motion: reduce) {
      .tab-nav__item {
        transition: none; } }
    .tab-nav__item.active {
      cursor: default; }
      .tab-nav__item.active .tab-nav__link {
        cursor: default; }
  .tab-nav__link {
    display: block;
    font-size: 1.6rem;
    text-align: center;
    text-decoration: none !important;
    letter-spacing: 0;
    border: none;
    box-shadow: none;
    outline: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
    padding: 0 15px; }

.tab-content {
  position: relative; }
  .tab-content__item {
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out; }
    @media screen and (prefers-reduced-motion: reduce) {
      .tab-content__item {
        transition: none; } }
    .tab-content__item.is-visible {
      position: static;
      top: auto;
      left: auto;
      visibility: visible;
      z-index: 2;
      opacity: 1; }

#controlling {
  background-color: #f3f3f3; }

#maintenance {
  background-color: #f3f3f3; }

@media (min-width: 992px) {
  .capabilities .col-lg-3 {
    flex: 0 0 33% !important;
    max-width: 33% !important; } }

.capabilities .__title {
  font-weight: 700;
  font-family: Quicksand,sans-serif; }

.capabilities .counter-capabilities {
  font-weight: 700;
  font-size: 32px;
  font-family: Quicksand,sans-serif;
  margin-bottom: 0 !important; }

#brands {
  padding-top: 0 !important;
  background-color: #2368fb; }

#testimonials {
  padding-bottom: 90px !important;
  background-color: #2368fb; }

.reviewer-company {
  font-weight: bold;
  font-size: 14px; }

#brands-small {
  background-color: #f3f3f3; }

.header-progress-bar {
  position: fixed;
  top: 105px;
  z-index: 1;
  width: 100%;
  background-color: #f1f1f1; }

.header-progress-bar h2 {
  text-align: center; }

.progress-container {
  width: 100%;
  height: 8px;
  background: #ccc; }

.progress-bar {
  height: 8px;
  background: #2368fb;
  width: 0%; }

.section-benefits {
  padding-bottom: 90px !important;
  padding-top: 0 !important; }

.section-benefits-first {
  padding-bottom: 40px !important; }

/* --------------------------------
   tags list
-------------------------------- */
.tags-list {
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px; }
  .tags-list ul {
    margin-top: -5px;
    margin-left: -5px; }
  .tags-list li {
    display: inline-block;
    vertical-align: top;
    margin-top: 5px;
    margin-left: 5px; }
  .tags-list a {
    display: block;
    padding: 5px 10px;
    line-height: 1.3;
    font-size: 0.8rem;
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    cursor: pointer;
    white-space: nowrap;
    outline: none;
    -webkit-user-drag: none;
    user-drag: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    border-radius: 10px; }

/* --------------------------------
   top button
-------------------------------- */
#btn-to-top-wrap {
  display: none;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 3; }
  @media only screen and (min-width: 561px) {
    #btn-to-top-wrap {
      right: 25px;
      bottom: 25px; } }

#btn-to-top {
  display: block;
  width: 44px;
  height: 44px;
  line-height: 42px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #2368fb;
  transition: opacity 0.3s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    #btn-to-top {
      transition: none; } }
  #btn-to-top:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-bottom: 6px solid #fff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent; }
  #btn-to-top:hover {
    opacity: 1; }

/* --------------------------------
   widget
-------------------------------- */
.widget {
  position: relative;
  margin-top: 40px; }
  .widget:first-child {
    margin-top: 0; }
    .widget:first-child .widget-title {
      margin-top: -0.2em; }

.widget--categories .list__item {
  margin-top: 15px;
  line-height: 1.2;
  font-size: 1.6rem;
  font-weight: 700; }
  .widget--categories .list__item:first-child {
    margin-top: 0; }
  .widget--categories .list__item__link {
    display: block;
    text-decoration: none; }
    .widget--categories .list__item__link:not(:hover) {
      color: #333; }
  .widget--categories .list__item span {
    margin-left: 15px;
    float: right; }

.widget--posts article {
  margin-top: 20px;
  line-height: 1.4; }
  .widget--posts article:first-child {
    margin-top: 0; }

.widget--posts .__image-wrap {
  width: 34%;
  max-width: 100px;
  padding-right: 20px; }

.widget--posts .__image {
  position: relative;
  width: 100%;
  height: 0;
  margin: auto;
  padding-top: 100%;
  overflow: hidden; }
  .widget--posts .__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
    font-family: "object-fit: cover; object-position: 50% 50%"; }

.widget--posts .__title {
  margin-bottom: 7px; }

.img-fluid-small {
  max-width: 80%; }

.cookiealert {
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 !important;
  z-index: 999;
  opacity: 0;
  background: #f3f3f3;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  color: #1d2124;
  border-radius: 0 !important;
  border: none !important;
  box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.2); }
  .cookiealert .btn {
    padding: 0.5rem 4.6rem !important;
    font-size: 1.64rem !important;
    border-radius: 20px !important;
    background-color: #2368fb !important;
    margin-top: 15px;
    text-transform: none !important; }

.cookie-alert-col {
  margin: auto; }

.cookiealert.show {
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 1000ms; }

.cookiealert a {
  text-decoration: underline; }

.cookiealert .acceptcookies {
  margin-left: 10px;
  vertical-align: baseline; }

/* --------------------------------
   authorization
-------------------------------- */
.authorization {
  padding: 30px 0; }
  .authorization .site-logo {
    margin-bottom: 30px; }
  .authorization__form {
    width: 100%;
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 20px;
    background-color: #fff;
    font-size: 1.6rem; }
    .authorization__form--shadow {
      box-shadow: 0px 0px 68px 0px rgba(174, 175, 175, 0.17); }
    .authorization__form .__title {
      margin-bottom: 30px;
      text-align: center; }

@media (min-width: 576px) {
  .authorization .site-logo {
    margin-bottom: 40px; }
  .authorization__form {
    padding: 60px 30px; } }

/* --------------------------------
   brands list
-------------------------------- */
.brands-list .__inner {
  margin-bottom: -30px; }

.brands-list .__item {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  text-align: center; }

.brands-list .__image {
  margin: auto;
  opacity: 0.2;
  transition: opacity 0.5s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .brands-list .__image {
      transition: none; } }
  .brands-list .__image:hover {
    opacity: 0.8; }

/* --------------------------------
   company contacts
-------------------------------- */
.company-contacts--s1 > .__inner {
  margin-bottom: -25px; }

.company-contacts--s1 .__item {
  margin-bottom: 25px; }

.company-contacts--s1 .__name {
  margin-bottom: 10px; }

.company-contacts--s1 p {
  margin-top: 10px;
  margin-bottom: 10px; }
  .company-contacts--s1 p a {
    color: inherit; }

.company-contacts--s2.text-white .__name {
  color: inherit; }

.company-contacts--s2 .__phone {
  line-height: 1.2;
  font-size: 3.5rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  letter-spacing: -0.03em; }
  @media (min-width: 576px) {
    .company-contacts--s2 .__phone {
      font-size: 4rem; } }

.company-contacts--s2 a:not([class]) {
  color: inherit; }

.company-contacts--s3 .company-contacts__list {
  line-height: 1.3; }
  .company-contacts--s3 .company-contacts__list li {
    margin-top: 20px;
    padding-left: 50px; }
    .company-contacts--s3 .company-contacts__list li:first-child {
      margin-top: 0; }
    .company-contacts--s3 .company-contacts__list li:after {
      content: "";
      display: table;
      clear: left; }

.company-contacts--s3 .__ico {
  float: left;
  width: 1em;
  margin-left: -50px;
  line-height: 1;
  font-size: 2.5rem;
  color: #2368fb; }

.company-contacts--s3 a:not([class]) {
  color: inherit; }

/* --------------------------------
   compare table
-------------------------------- */
.pricing-table--s4 td {
  font-size: 16px !important; }

.font-size-16px {
  font-size: 16px !important; }

.pricing-cloud-text {
  font-size: 13px !important; }

.compare-table .__inner {
  margin-bottom: -50px; }

.compare-table .__item {
  position: relative;
  width: 100%;
  background-color: #fff;
  margin-bottom: 50px;
  padding: 40px 30px;
  overflow: hidden; }
  .compare-table .__item--rounded {
    border-radius: 20px; }
  .compare-table .__item--shadow {
    box-shadow: 0px 0px 20px 0px rgba(174, 175, 175, 0.4); }
  @media (min-width: 576px) {
    .compare-table .__item {
      display: flex;
      flex-direction: column; }
      .compare-table .__item .__header {
        flex: 0 0 auto; }
      .compare-table .__item .__body {
        flex: 1 0 auto; }
      .compare-table .__item .__footer {
        flex: 0 0 auto; } }

.compare-table .__ico {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }
  .compare-table .__ico > img,
  .compare-table .__ico > svg {
    display: block; }

.compare-table .__desc-list {
  font-size: 1.6rem; }

/* --------------------------------
   content
-------------------------------- */
.content-container .demo-selection {
  background-color: #2368fb;
  color: #fff; }

.content-container ol:not([class]),
.content-container ul:not([class]) {
  line-height: 1.2;
  margin-top: 25px;
  margin-bottom: 25px; }
  .content-container ol:not([class]):first-child,
  .content-container ul:not([class]):first-child {
    margin-top: 0 !important; }
  .content-container ol:not([class]):last-child,
  .content-container ul:not([class]):last-child {
    margin-bottom: 0 !important; }
  .content-container ol:not([class]) li,
  .content-container ul:not([class]) li {
    margin-top: 15px;
    padding-left: 15px; }
    .content-container ol:not([class]) li:before,
    .content-container ul:not([class]) li:before {
      float: left;
      margin-left: -15px;
      margin-right: 5px; }
    .content-container ol:not([class]) li:first-child,
    .content-container ul:not([class]) li:first-child {
      margin-top: 0; }

.content-container ol:not([class]) {
  counter-reset: num; }
  .content-container ol:not([class]) li:before {
    counter-increment: num;
    content: counter(num) ".";
    font-weight: 700;
    color: #2368fb; }

.content-container ul:not([class]) li:before {
  content: "";
  width: 0;
  height: 0;
  margin-top: 7px;
  border: 3px solid #2368fb;
  border-radius: 50%; }

.content-container hr {
  margin-top: 60px;
  margin-bottom: 60px;
  border: none;
  border-top: 1px solid #ebebeb; }
  .content-container hr:first-child {
    margin-top: 0 !important; }
  .content-container hr:last-child {
    margin-bottom: 0 !important; }

.content-container .blockquot {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 20px;
  border-left: 4px solid #2368fb;
  line-height: 1.4;
  font-size: 2rem;
  color: #333; }
  .content-container .blockquot:first-child {
    margin-top: 0; }
  .content-container .blockquot:last-child {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .content-container .blockquot {
      padding-left: 30px;
      line-height: 1.8; } }

.content-container .dropcaps .first-letter {
  float: left;
  margin-right: 10px;
  line-height: 0.9;
  font-size: 4.6rem;
  font-weight: 800;
  color: #2368fb; }

.modal-dialog {
  font-size: 30px; }

.modal-header {
  border-bottom: 0 !important;
  padding: 5% 5% 2%;
  line-height: 1.6;
  font-size: 1.8rem;
  font-family: -apple-system,BlinkMacSystemFont,"Nunito Sans",sans-serif;
  font-weight: 400;
  color: #2a2c2f; }

.modal-body {
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  line-height: 1.6;
  font-size: 1.8rem;
  font-family: -apple-system,BlinkMacSystemFont,"Nunito Sans",sans-serif;
  font-weight: 400;
  color: #2a2c2f; }

.modal-content {
  border-radius: 1.125rem !important; }

#contact-modal {
  padding-top: 2%; }
  #contact-modal .textfield {
    color: #2a2c2f !important; }
  #contact-modal .__title {
    font-size: 3rem !important; }

/* --------------------------------
   faq
-------------------------------- */
.faq .__inner {
  margin-bottom: -35px; }

.faq .__item {
  position: relative;
  margin-bottom: 35px; }

.faq .__title {
  margin: 0; }

.faq p {
  margin-top: 5px;
  margin-bottom: 5px; }

.faq--numbered {
  counter-reset: faq-num; }
  .faq--numbered .__title:before {
    display: inline;
    counter-increment: faq-num;
    content: counter(faq-num, decimal-leading-zero) ". "; }

/* --------------------------------
   feature
-------------------------------- */
.feature .__inner {
  margin-bottom: -50px; }

.feature .__item {
  position: relative;
  width: 100%;
  margin-bottom: 50px; }
  .feature .__item .__ico {
    display: inline-block;
    vertical-align: top;
    line-height: 1; }
    .feature .__item .__ico > svg {
      vertical-align: middle; }
  .feature .__item .__title {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: none; }
  .feature .__item p {
    margin-top: 10px;
    margin-bottom: 10px; }

.feature--s1 .__ico {
  min-width: 50px; }

.feature--s2 {
  margin-top: 60px; }
  .feature--s2 .col:first-child .__item:first-child,
  .feature--s2 [class*=col-]:first-child .__item:first-child {
    margin-top: -60px; }
  .feature--s2 .__item {
    padding: 25px 15px 30px;
    background-color: #fff; }
    .feature--s2 .__item--rounded {
      border-radius: 3px; }
    .feature--s2 .__item--shadow {
      box-shadow: 0px 0px 68px 0px rgba(174, 175, 175, 0.17); }
    @media (min-width: 576px) {
      .feature--s2 .__item {
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 50px; } }

/* --------------------------------
   footer
-------------------------------- */
.footer {
  position: relative;
  font-size: 1.6rem; }
  .footer__line {
    position: relative; }
  .footer__item {
    position: relative;
    width: 100%;
    float: left;
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 2; }
  .footer__navigation {
    line-height: 1.4;
    font-weight: 700; }
    .footer__navigation:first-child {
      margin-top: -10px; }
    .footer__navigation li {
      margin-top: 10px; }
      .footer__navigation li.active a {
        text-decoration: underline; }
    .footer__navigation a {
      color: #1d2124;
      text-decoration: none; }
  .footer__address {
    font-style: normal; }
    .footer__address--s1 {
      line-height: 1.8;
      font-weight: 700; }
    .footer__address--s2 {
      line-height: 1.6;
      font-weight: 700; }
      .footer__address--s2 li {
        margin-top: 15px;
        padding-left: 35px; }
        .footer__address--s2 li:first-child {
          margin-top: 0 !important; }
        .footer__address--s2 li:after {
          content: "";
          display: table;
          clear: left; }
      .footer__address--s2 .__ico {
        float: left;
        margin-left: -35px;
        width: 1em;
        line-height: 1;
        font-size: 2.3rem; }
        .footer__address--s2 .__ico:before {
          vertical-align: top; }
    .footer__address--s3 {
      line-height: 1.2; }
    .footer__address p {
      margin-top: 10px;
      margin-bottom: 10px; }
      .footer__address p:first-child {
        margin-top: 0 !important; }
      .footer__address p:last-child {
        margin-bottom: 0 !important; }
    .footer__address a {
      color: inherit; }
  .footer__wave {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1; }
  .footer .__copy {
    font-size: 1.4rem; }
  .footer .__dev {
    color: inherit; }
    .footer .__dev:hover, .footer .__dev:focus {
      text-decoration: none; }

.footer__item .textfield {
  color: #343a3f;
  background-clip: unset !important; }

.footer__item ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1;
  /* Firefox */ }

.footer__item :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red; }

.footer__item ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red; }

.footer--color-light {
  color: #1d2124; }
  .footer--color-light .footer__item__title {
    color: inherit; }
  footer__navigation .footer--color-light .footer__navigation li.active a {
    color: #1d2124; }
  .footer--color-light .footer__navigation a:hover {
    color: #2368fb; }

.footer--color-dark {
  color: #1d2124; }
  .footer--color-dark .footer__navigation i {
    margin-right: 10px !important;
    font-size: 10px; }
  .footer--color-dark .footer__navigation li.active a {
    color: #1d2124; }
  .footer--color-dark .footer__navigation a:hover {
    color: #2368fb; }
  .footer--color-dark .footer__address--s3 strong {
    color: #333; }

.footer--s3 .footer__line {
  color: #1d2124; }
  .footer--s3 .footer__line--first {
    background-color: #fff;
    padding-top: 50px;
    padding-bottom: 70px; }
  .footer--s3 .footer__line--second {
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px; }

.footer--s3 .__copy {
  color: #1d2124;
  font-size: small; }
  .footer--s3 .__copy a {
    color: #1d2124 !important;
    text-decoration: none !important; }
  .footer--s3 .__copy a:hover {
    color: #1d2124 !important;
    text-decoration: none !important; }

.footer--s3 .footer__wave {
  height: 150px; }

.footer__navigation a {
  font-weight: 500; }

/* --------------------------------
   info block
-------------------------------- */
.info-block {
  position: relative; }

@media (min-width: 768px) {
  .info-block--s1 .image-container {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%); } }

.info-block--s2 .image-container {
  position: absolute;
  top: 40%; }
  .info-block--s2 .image-container:nth-of-type(1) {
    left: -15px; }
  .info-block--s2 .image-container:nth-of-type(2) {
    right: -15px; }

/* --------------------------------
   posts
-------------------------------- */
.posts .__inner {
  margin-bottom: -30px; }

.posts .__item {
  position: relative;
  width: 100%;
  margin-bottom: 30px; }
  .posts .__item .__content {
    position: relative;
    line-height: 1.6; }
  .posts .__item .__title {
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: none; }
  .posts .__item .custom-btn {
    margin-top: 10px; }
  .posts .__item:hover .__image img {
    transform: scale(1.2) translateZ(0); }

.posts .__item--preview {
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  transition: box-shadow 300ms ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .posts .__item--preview {
      transition: none; } }
  @media (min-width: 576px) {
    .posts .__item--preview {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .posts .__item--preview .__header {
        flex: 0 0 auto; }
      .posts .__item--preview .__body {
        flex: 1 0 auto; }
      .posts .__item--preview .__footer {
        flex: 0 0 auto; } }
  .posts .__item--preview > div {
    width: 100%; }
  .posts .__item--preview .__image {
    position: relative;
    width: 100%;
    height: 0;
    margin: auto;
    overflow: hidden; }
    .posts .__item--preview .__image img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: 50% 50%;
      object-position: 50% 50%;
      font-family: "object-fit: cover; object-position: 50% 50%";
      transition: transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 200ms; }
      @media screen and (prefers-reduced-motion: reduce) {
        .posts .__item--preview .__image img {
          transition: none; } }
  .posts .__item--preview .tags-list {
    padding-top: 5px; }
  .posts .__item--preview p {
    margin-top: 10px;
    margin-bottom: 10px; }
    .posts .__item--preview p:first-child {
      margin-top: 0; }
    .posts .__item--preview p:last-child {
      margin-bottom: 0; }

.post-meta {
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px;
  color: #b6b6b6; }
  .post-meta__item {
    display: inline-block;
    margin-left: 20px;
    line-height: 1.2;
    font-size: 1.2rem;
    letter-spacing: 0; }
    .post-meta__item:first-child {
      margin-left: 0; }
    .post-meta__item a {
      color: inherit; }

.post-author__img {
  width: 70px;
  margin-right: 15px;
  overflow: hidden;
  border-radius: 50%; }

.post-author__name {
  display: block;
  font-size: 2rem;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  color: #333; }

.posts--s1 .__item--rounded {
  border-radius: 5px; }

.posts--s1 .__item--shadow {
  box-shadow: 0px 0px 68px 0px rgba(174, 175, 175, 0.17); }
  .posts--s1 .__item--shadow:hover {
    box-shadow: 0px 0px 68px 0px rgba(174, 175, 175, 0.44); }

.posts--s1 .__item--preview {
  padding-bottom: 35px; }
  .posts--s1 .__item--preview .__image {
    padding-top: 87.03704%; }
    .posts--s1 .__item--preview .__image--rounded {
      border-radius: 5px; }
  .posts--s1 .__item--preview .__content,
  .posts--s1 .__item--preview .tags-list {
    padding-left: 25px;
    padding-right: 25px; }
  .posts--s1 .__item--preview .__content {
    padding-top: 30px;
    transition: transform 300ms ease-in-out; }
    @media screen and (prefers-reduced-motion: reduce) {
      .posts--s1 .__item--preview .__content {
        transition: none; } }

.posts--s2 .__item--preview .__date-post {
  display: inline-block;
  top: 0;
  left: 0;
  min-width: 60px;
  margin-bottom: 10px;
  padding: 8px 5px 11px;
  background-color: #2552e4;
  line-height: 1;
  font-size: 1.6rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  letter-spacing: -0.05em;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  z-index: 1; }
  .posts--s2 .__item--preview .__date-post strong {
    display: block;
    margin-bottom: 5px;
    font-size: 4rem; }

.posts--s2 .__item--preview .__image {
  min-height: 200px;
  padding-top: 63.51351%; }
  .posts--s2 .__item--preview .__image img {
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto; }

.posts--s2 .__item--preview .__content {
  padding-top: 15px; }

.posts--s2 .__item--preview .__more-link {
  font-size: 1.7rem;
  font-weight: 700; }

.posts--s2 .__item--preview:hover .__image img {
  transform: scale(1); }

.posts--s3 .__item--rounded {
  border-radius: 5px; }

.posts--s3 .__item--shadow {
  box-shadow: 0px 0px 68px 0px rgba(174, 175, 175, 0.17); }
  .posts--s3 .__item--shadow:hover {
    box-shadow: 0px 0px 68px 0px rgba(174, 175, 175, 0.44); }

.posts--s3 .__item--preview .__image {
  padding-top: 87.03704%; }
  .posts--s3 .__item--preview .__image--rounded {
    border-radius: 5px; }

.posts--s3 .__item--preview .__content {
  padding: 20px 30px; }

.posts--s3 .__item--preview .__title:last-child {
  margin-bottom: 0; }

.posts--s4 .__item--rounded {
  border-radius: 5px; }

.posts--s4 .__item--shadow {
  box-shadow: 0px 0px 68px 0px rgba(174, 175, 175, 0.17); }
  .posts--s4 .__item--shadow:hover {
    box-shadow: 0px 0px 68px 0px rgba(174, 175, 175, 0.44); }

.posts--s4 .__item--preview {
  padding: 30px; }

/* --------------------------------
   pricing table
-------------------------------- */
.pricing-card {
  display: block !important; }

.pricing-table .__inner {
  margin-bottom: -50px; }

.pricing-table .__info {
  bottom: 28px !important;
  font-size: 2rem !important;
  font-weight: 400 !important; }

.pricing-table .__item--best--choice {
  border: 3px solid #2368fb !important;
  box-shadow: 7px 7px rgba(172, 214, 97, 0.2) !important; }

.pricing-table .__item {
  position: relative;
  width: 100%;
  background-color: #fff;
  margin-bottom: 50px;
  padding: 60px 15px;
  text-align: center;
  overflow: hidden; }
  .pricing-table .__item--rounded {
    border-radius: 5px; }
  .pricing-table .__item--bordered {
    padding: 56px 11px;
    border: 4px solid; }
  @media (min-width: 576px) {
    .pricing-table .__item {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .pricing-table .__item .__header {
        flex: 0 0 auto; }
      .pricing-table .__item .__body {
        flex: 1 0 auto; }
      .pricing-table .__item .__footer {
        flex: 0 0 auto; } }

.pricing-table .__label {
  position: absolute;
  line-height: 1.3;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }
  .pricing-table .__label + .__title {
    margin-top: 0; }

.pricing-table .__price {
  margin-top: 25px;
  margin-bottom: 25px;
  line-height: 1;
  font-size: 6rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  letter-spacing: -3px;
  color: #333; }
  .pricing-table .__price:first-child {
    margin-top: 0; }
  .pricing-table .__price:last-child {
    margin-bottom: 0; }
  .pricing-table .__price sup {
    font-size: 3.5rem; }
  .pricing-table .__price sub {
    bottom: auto;
    font-size: 3rem;
    letter-spacing: -1px; }

.pricing-table .__desc-list {
  line-height: 1.5;
  line-height: 1.2;
  font-size: 1.6rem; }
  .pricing-table .__desc-list li {
    margin-top: 20px; }
    .pricing-table .__desc-list li:first-child {
      margin-top: 0; }

.pricing-table--s1 .__item--shadow {
  box-shadow: 0px 0px 29px 0px rgba(174, 175, 175, 0.11); }

.pricing-table--s1 .__item--active {
  background-color: #2368fb;
  color: #fff; }
  .pricing-table--s1 .__item--active .__title,
  .pricing-table--s1 .__item--active .__price,
  .pricing-table--s1 .__item--active .__value {
    color: inherit; }
  .pricing-table--s1 .__item--active .__label {
    top: 4px;
    right: 4px;
    background-color: #fff;
    padding: 7px 20px;
    color: #01a1d2; }

.pricing-table--s1 .disabled {
  color: #c5c5c5; }

.pricing-table--s2 .__item--shadow {
  box-shadow: 0px 0px 29px 0px rgba(174, 175, 175, 0.11); }

.pricing-table--s2 .__item--active .__label {
  top: 0;
  right: 0;
  background-color: #e3306f;
  padding: 12px 25px;
  color: #fff; }

@media (min-width: 768px) {
  .pricing-table--s2 .__item--active {
    margin-bottom: 30px;
    padding-bottom: 80px; }
    .pricing-table--s2 .__item--active .__header {
      padding-bottom: 20px; } }

.pricing-table--s3 .__item--color-1 {
  border-color: #ff5252; }
  .pricing-table--s3 .__item--color-1 .__price {
    color: #ff5252; }
  .pricing-table--s3 .__item--color-1 .custom-btn:before {
    background: -moz-linear-gradient(0deg, #f63068 0%, #fa6e3d 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #f63068), color-stop(100%, #fa6e3d));
    background: -webkit-linear-gradient(0deg, #f63068 0%, #fa6e3d 100%);
    background: -o-linear-gradient(0deg, #f63068 0%, #fa6e3d 100%);
    background: -ms-linear-gradient(0deg, #f63068 0%, #fa6e3d 100%);
    background: linear-gradient(90deg, #f63068 0%, #fa6e3d 100%); }

.pricing-table--s3 .__item--color-2 {
  border-color: #26b251; }
  .pricing-table--s3 .__item--color-2 .__price {
    color: #26b251; }
  .pricing-table--s3 .__item--color-2 .custom-btn:before {
    background: -moz-linear-gradient(0deg, #2fb76b 0%, #8ac84b 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #2fb76b), color-stop(100%, #8ac84b));
    background: -webkit-linear-gradient(0deg, #2fb76b 0%, #8ac84b 100%);
    background: -o-linear-gradient(0deg, #2fb76b 0%, #8ac84b 100%);
    background: -ms-linear-gradient(0deg, #2fb76b 0%, #8ac84b 100%);
    background: linear-gradient(90deg, #2fb76b 0%, #8ac84b 100%); }

.pricing-table--s3 .__item--color-3 {
  border-color: #255da9; }
  .pricing-table--s3 .__item--color-3 .__price {
    color: #255da9; }
  .pricing-table--s3 .__item--color-3 .custom-btn:before {
    background: -moz-linear-gradient(0deg, #255da9 0%, #00a4d4 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #255da9), color-stop(100%, #00a4d4));
    background: -webkit-linear-gradient(0deg, #255da9 0%, #00a4d4 100%);
    background: -o-linear-gradient(0deg, #255da9 0%, #00a4d4 100%);
    background: -ms-linear-gradient(0deg, #255da9 0%, #00a4d4 100%);
    background: linear-gradient(90deg, #255da9 0%, #00a4d4 100%); }

.pricing-table--s3 .__item--color-4 {
  border-color: #ffb042; }
  .pricing-table--s3 .__item--color-4 .__price {
    color: #ffb042; }
  .pricing-table--s3 .__item--color-4 .custom-btn:before {
    background: -moz-linear-gradient(0deg, #fbbe00 0%, #fdd968 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #fbbe00), color-stop(100%, #fdd968));
    background: -webkit-linear-gradient(0deg, #fbbe00 0%, #fdd968 100%);
    background: -o-linear-gradient(0deg, #fbbe00 0%, #fdd968 100%);
    background: -ms-linear-gradient(0deg, #fbbe00 0%, #fdd968 100%);
    background: linear-gradient(90deg, #fbbe00 0%, #fdd968 100%); }

.pricing-table--s3 .__item--active {
  background: -moz-linear-gradient(90deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #00a4d4), color-stop(40%, #1165b2), color-stop(82%, #6b5392), color-stop(100%, #6b5392));
  background: -webkit-linear-gradient(90deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
  background: -o-linear-gradient(90deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
  background: -ms-linear-gradient(90deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
  background: linear-gradient(0deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
  color: #fff; }
  .pricing-table--s3 .__item--active .__title,
  .pricing-table--s3 .__item--active .__price,
  .pricing-table--s3 .__item--active .__value {
    color: inherit; }
  .pricing-table--s3 .__item--active .__label {
    top: 4px;
    right: 4px;
    background-color: #fff;
    padding: 7px 20px;
    color: #01a1d2; }
  .pricing-table--s3 .__item--active .custom-btn {
    color: #333; }
    .pricing-table--s3 .__item--active .custom-btn:hover, .pricing-table--s3 .__item--active .custom-btn:focus {
      background-color: #2d3a49;
      border-color: #2d3a49;
      color: #fff; }

.pricing-table--s3 .__item:not(.__item--active) .custom-btn {
  background-color: #2d3a49;
  border-color: #2d3a49; }
  .pricing-table--s3 .__item:not(.__item--active) .custom-btn:hover:before, .pricing-table--s3 .__item:not(.__item--active) .custom-btn:focus:before {
    opacity: 0; }

.pricing-table--s3 .__value {
  display: block;
  font-size: 2rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  color: #333; }

.pricing-table--s3 .custom-btn {
  background-color: #fff;
  border-color: #fff;
  color: #fff; }
  .pricing-table--s3 .custom-btn:before {
    content: ""; }

.pricing-table--s4 table {
  background-color: #fff;
  font-size: 1.6rem; }
  .pricing-table--s4 table.rounded {
    border-radius: 5px; }
  .pricing-table--s4 table.shadow {
    box-shadow: 0px 0px 29px 0px rgba(174, 175, 175, 0.11); }
  .pricing-table--s4 table .__price {
    font-size: 2rem;
    letter-spacing: -2px; }
    .pricing-table--s4 table .__price sup,
    .pricing-table--s4 table .__price sub {
      font-size: inherit; }
    .pricing-table--s4 table .__price sup {
      top: auto; }

.pricing-table--s4 tbody tr:nth-of-type(2n) {
  background-color: #f7f7f7; }
  .pricing-table--s4 tbody tr:nth-of-type(2n) td.active {
    background-color: rgba(35, 104, 251, 0.8); }

.pricing-table--s4 tbody td {
  height: 62px; }

.pricing-table--s4 tbody th {
  height: 90px; }

.pricing-table--s4 tfoot td {
  padding-top: 35px;
  padding-bottom: 45px; }

.pricing-table--s4 th:first-child,
.pricing-table--s4 td:first-child {
  width: 25%;
  min-width: 250px;
  padding-left: 4%;
  text-align: left; }

.pricing-table--s4 th.active,
.pricing-table--s4 td.active {
  background-color: #2368fb;
  color: #fff; }
  .pricing-table--s4 th.active .__title,
  .pricing-table--s4 th.active .__price,
  .pricing-table--s4 td.active .__title,
  .pricing-table--s4 td.active .__price {
    color: inherit; }

.pricing-table--s4 td {
  padding-left: 10px;
  padding-right: 10px; }

.pricing-table--s4 .__item--shadow {
  box-shadow: 0px 0px 29px 0px rgba(174, 175, 175, 0.11); }

.pricing-table--s4 .__item--active {
  background: -moz-linear-gradient(90deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #00a4d4), color-stop(40%, #1165b2), color-stop(82%, #6b5392), color-stop(100%, #6b5392));
  background: -webkit-linear-gradient(90deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
  background: -o-linear-gradient(90deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
  background: -ms-linear-gradient(90deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
  background: linear-gradient(0deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
  color: #fff; }
  .pricing-table--s4 .__item--active .__title,
  .pricing-table--s4 .__item--active .__price,
  .pricing-table--s4 .__item--active .__name,
  .pricing-table--s4 .__item--active .__value {
    color: inherit; }
  .pricing-table--s4 .__item--active .__label {
    top: 4px;
    right: 4px;
    background-color: #fff;
    padding: 7px 20px;
    color: #01a1d2; }
  .pricing-table--s4 .__item--active .__desc-list li:nth-of-type(2n+1) {
    background: rgba(255, 255, 255, 0.15); }

.pricing-table--s4 .__body {
  width: 100%; }

.pricing-table--s4 .__name {
  margin-bottom: 10px; }

.pricing-table--s4 .__desc-list {
  margin-left: -15px;
  margin-right: -15px; }
  .pricing-table--s4 .__desc-list li {
    margin: 0;
    padding: 10px 15px; }
    .pricing-table--s4 .__desc-list li:nth-of-type(2n+1) {
      background-color: #f7f7f7; }
    .pricing-table--s4 .__desc-list li span:first-child {
      float: right; }

.pricing-table--s5 .__item {
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent; }

.pricing-table--s5 .__body {
  text-align: center; }

.pricing-table--s5 .__title,
.pricing-table--s5 .__price,
.pricing-table--s5 .__desc-list {
  color: #fff; }

.pricing-table--s5 .__desc-list {
  display: inline-block;
  vertical-align: top; }

.pricing-table--s5 .custom-btn {
  color: #fff;
  border-color: #fff; }
  .pricing-table--s5 .custom-btn:hover, .pricing-table--s5 .custom-btn:focus {
    background-color: #fff;
    color: #333; }

.pricing-info {
  font-size: 14px; }

.platinum-storage .__price {
  letter-spacing: 0px !important; }

/* --------------------------------
   projects
-------------------------------- */
.projects .__item {
  position: relative;
  align-self: stretch;
  width: 100%; }

.projects .__image {
  position: relative;
  width: 100%;
  height: 0;
  margin: auto;
  overflow: hidden; }
  .projects .__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
    font-family: "object-fit: cover; object-position: 50% 50%"; }

.projects .__filter {
  margin-left: -30px;
  margin-bottom: 40px;
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px; }
  .projects .__filter li {
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
    margin-bottom: 20px;
    letter-spacing: 0; }
  .projects .__filter a {
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
    line-height: 1.2;
    font-size: 1.6rem;
    font-weight: 700;
    text-decoration: none;
    color: #333; }
    .projects .__filter a:hover, .projects .__filter a.selected {
      color: #a3a3a3; }
    .projects .__filter a.selected {
      border-color: #2368fb; }

.projects--s1 .__inner {
  margin-bottom: -50px; }

.projects--s1 .__item {
  margin-bottom: 50px;
  padding: 50px 15px;
  background-color: #fff;
  text-align: center;
  transition: box-shadow 0.3s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .projects--s1 .__item {
      transition: none; } }
  .projects--s1 .__item--shadow {
    box-shadow: 0px 0px 68px 0px rgba(174, 175, 175, 0.17); }
    .projects--s1 .__item--shadow:hover {
      box-shadow: 0px 0px 68px 0px rgba(90, 90, 90, 0.54); }
  .projects--s1 .__item > div {
    width: 100%; }
  .projects--s1 .__item .__header,
  .projects--s1 .__item .__body {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto; }
  @media (min-width: 576px) {
    .projects--s1 .__item {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .projects--s1 .__item .__header {
        flex: 0 0 auto; }
      .projects--s1 .__item .__body {
        flex: 1 0 auto; }
      .projects--s1 .__item .__footer {
        flex: 0 0 auto; } }

.projects--s1 .__image {
  min-height: 200px;
  padding-top: 76.66667%; }
  .projects--s1 .__image img {
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%; }

.projects--s2-a .__inner {
  margin-bottom: -30px; }

.projects--s2-a .__item {
  margin-bottom: 30px; }
  @media (min-width: 576px) {
    .projects--s2-a .__item[data-x="2"][data-y="1"] .__image {
      padding-top: -webkit-calc(50% - 15px);
      padding-top: -moz-calc(50% - 15px);
      padding-top: calc(50% - 15px); }
    .projects--s2-a .__item[data-x="1"][data-y="2"] .__image {
      padding-top: -webkit-calc(200% + 30px);
      padding-top: -moz-calc(200% + 30px);
      padding-top: calc(200% + 30px); } }

@media (min-width: 576px) {
  .projects--s2-b .__item[data-x="2"][data-y="1"] .__image {
    padding-top: 50%; }
  .projects--s2-b .__item[data-x="1"][data-y="2"] .__image {
    padding-top: 200%; } }

.projects--s2 .__item .__image {
  padding-top: 100%; }

.projects--s2 .__item:hover .__content {
  opacity: 1; }

.projects--s2 .__content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: rgba(75, 75, 226, 0.75);
  opacity: 0;
  text-align: center;
  color: #fff;
  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .projects--s2 .__content {
      transition: none; } }

.projects--s2 .__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .projects--s2 .__link + .__title {
    margin-top: 0; }

.projects--s2 .__title {
  color: inherit; }

.projects--s3-a .__inner {
  margin-bottom: -30px; }

.projects--s3-a .__item {
  margin-bottom: 30px; }
  @media (min-width: 576px) {
    .projects--s3-a .__item[data-x="2"][data-y="1"] .__image {
      padding-top: -webkit-calc(50% - 15px);
      padding-top: -moz-calc(50% - 15px);
      padding-top: calc(50% - 15px); }
    .projects--s3-a .__item[data-x="1"][data-y="2"] .__image {
      padding-top: -webkit-calc(200% + 30px);
      padding-top: -moz-calc(200% + 30px);
      padding-top: calc(200% + 30px); } }

@media (min-width: 576px) {
  .projects--s3-b .__item[data-x="2"][data-y="1"] .__image {
    padding-top: 50%; }
  .projects--s3-b .__item[data-x="1"][data-y="2"] .__image {
    padding-top: 200%; } }

.projects--s3 .__item .__image {
  padding-top: 100%; }

.projects--s3 .__item:hover .__content {
  background-color: rgba(51, 99, 239, 0.75); }

.projects--s3 .__content {
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px 15px;
  color: #fff;
  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .projects--s3 .__content {
      transition: none; } }
  @media (min-width: 576px) {
    .projects--s3 .__content {
      padding: 30px; } }

.projects--s3 .__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .projects--s3 .__link + .__title {
    margin-top: 0; }

.projects--s3 .__title {
  color: inherit; }

.projects--s4 .slick-list {
  margin-top: -30px;
  margin-bottom: -30px; }

.projects--s4 .slick-slide {
  padding-left: 15px;
  padding-right: 15px; }
  .projects--s4 .slick-slide .__item {
    margin-top: 30px;
    margin-bottom: 30px; }

.projects--s4 .__item {
  transition: box-shadow 0.3s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .projects--s4 .__item {
      transition: none; } }
  .projects--s4 .__item--shadow {
    box-shadow: 0px 0px 40px 0px rgba(174, 175, 175, 0.17); }

.projects--s4 .__image {
  min-height: 200px;
  padding-top: 100%; }

.projects--s4 .__content {
  padding: 25px 15px 50px;
  background-color: #fff;
  text-align: center; }
  @media (min-width: 768px) {
    .projects--s4 .__content {
      padding-left: 30px;
      padding-right: 30px; } }

/* --------------------------------
   review
-------------------------------- */
.testimonial-ico {
  display: block;
  width: 70px;
  height: 70px;
  padding: 15px 0;
  background-color: white;
  line-height: 1;
  font-size: 8rem;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  color: #2368fb;
  border-radius: 50%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

.review {
  position: relative; }
  .review__item {
    position: relative;
    width: 100%; }
    .review__item__author-image {
      line-height: 1;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
      border-radius: 50%; }
    .review__item__author-name, .review__item__author-position {
      display: block;
      line-height: 1; }
  .review .__rating {
    line-height: 0;
    font-size: 0;
    letter-spacing: -1px; }
    .review .__rating i {
      display: inline-block;
      vertical-align: top;
      margin-left: 0.3em;
      line-height: 1;
      font-size: 1.2rem;
      color: #fac655;
      letter-spacing: 0; }
      .review .__rating i:first-child {
        margin-left: 0; }

.review--slider .slick-slide {
  box-sizing: content-box; }

.review--slider .review__item {
  vertical-align: middle;
  width: auto !important;
  box-sizing: border-box; }

.review--s1.review--slider .slick-slider {
  padding-left: 45px;
  padding-right: 45px; }
  @media (min-width: 768px) {
    .review--s1.review--slider .slick-slider {
      padding-left: 65px;
      padding-right: 65px; } }
  @media (min-width: 1200px) {
    .review--s1.review--slider .slick-slider {
      padding-left: 85px;
      padding-right: 85px; } }

.review--s1.review--slider .slick-slide {
  padding-left: 15px;
  padding-right: 15px; }

.review--s1.review--slider .slick-arrow {
  position: absolute;
  top: 30px;
  font-size: 6rem;
  color: #2368fb; }

.review--s1.review--slider .slick-disabled {
  color: #d9dfe5; }

.review--s1.review--slider .slick-prev {
  left: 0; }

.review--s1.review--slider .slick-next {
  right: 0; }

.review--s1 .review__item {
  overflow: hidden; }
  .review--s1 .review__item__author-image {
    margin-bottom: 30px; }
  .review--s1 .review__item__author-position {
    font-size: 1.4rem;
    font-weight: 600;
    color: #2368fb; }
  @media (min-width: 768px) {
    .review--s1 .review__item {
      padding-left: 150px; }
      .review--s1 .review__item__author-image {
        float: left;
        margin-left: -150px;
        margin-bottom: 0; } }
  @media (min-width: 1200px) {
    .review--s1 .review__item {
      padding-left: 200px; }
      .review--s1 .review__item__author-image {
        margin-left: -200px; } }

@media (min-width: 576px) {
  .review--s2.review--slider {
    margin-left: calc((100% - 510px) / 2);
    margin-right: calc((100% - 510px) / 2); } }

@media (min-width: 768px) {
  .review--s2.review--slider {
    margin-left: calc((100% - 690px) / 2);
    margin-right: -5%; } }

@media (min-width: 992px) {
  .review--s2.review--slider {
    margin-left: calc((100% - 930px) / 2); } }

@media (min-width: 1200px) {
  .review--s2.review--slider {
    margin-left: calc((100% - 1140px) / 2); } }

.review--s2.review--slider .slick-list {
  margin: -30px -15px; }

.review--s2.review--slider .slick-track {
  align-items: stretch; }

.review--s2.review--slider .slick-slide > div:first-child {
  display: flex;
  align-self: stretch; }

.review--s2.review--slider .review__item--shadow {
  margin: 30px 15px; }

.review--s2 .review__item {
  background-color: #fff;
  padding: 25px 15px 30px; }
  .review--s2 .review__item--rounded {
    border-radius: 5px; }
  .review--s2 .review__item--shadow {
    box-shadow: 0px 0px 29px 0px rgba(174, 175, 175, 0.11); }
  @media (min-width: 576px) {
    .review--s2 .review__item {
      padding: 35px 30px 40px; } }
  @media (min-width: 1200px) {
    .review--s2 .review__item {
      padding: 50px;
      padding-top: 45px; } }

@media (min-width: 576px) {
  .review--s3.review--slider {
    margin-right: -55%; } }

@media (min-width: 768px) {
  .review--s3.review--slider {
    margin-right: -85%; } }

.review--s3.review--slider .slick-list {
  margin: -30px -15px; }

.review--s3.review--slider .slick-slide:not(.slick-current) .review__item {
  background-color: rgba(255, 255, 255, 0.8); }
  @media (min-width: 576px) {
    .review--s3.review--slider .slick-slide:not(.slick-current) .review__item {
      margin-top: 50px;
      margin-bottom: 50px;
      padding-top: 40px;
      padding-bottom: 40px; } }

.review--s3.review--slider .slick-slide > div:first-child {
  display: flex;
  align-self: stretch; }

.review--s3.review--slider .review__item {
  transition: margin 400ms cubic-bezier(0.43, 0.49, 0.51, 0.68) 400ms, padding 400ms cubic-bezier(0.43, 0.49, 0.51, 0.68) 400ms, background-color 300ms 400ms; }
  .review--s3.review--slider .review__item--shadow {
    margin: 30px 15px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .review--s3.review--slider .review__item {
      transition: none; } }

.review--s3 .review__item {
  background-color: #fff;
  padding: 30px 15px;
  font-size: 1.6rem; }
  .review--s3 .review__item--rounded {
    border-radius: 5px; }
  .review--s3 .review__item--shadow {
    box-shadow: 0px 0px 29px 0px rgba(174, 175, 175, 0.11); }
  @media (min-width: 576px) {
    .review--s3 .review__item {
      padding: 60px 30px; } }
  @media (min-width: 1200px) {
    .review--s3 .review__item {
      padding: 60px 50px; } }

@media (min-width: 576px) {
  .review--s4.review--slider {
    margin-left: calc((100% - 510px) / 2);
    margin-right: -10%; } }

@media (min-width: 768px) {
  .review--s4.review--slider {
    margin-left: calc((100% - 690px) / 2); } }

@media (min-width: 992px) {
  .review--s4.review--slider {
    margin-left: calc((100% - 930px) / 2); } }

@media (min-width: 1200px) {
  .review--s4.review--slider {
    margin-left: calc((100% - 1140px) / 2); } }

.review--s4.review--slider .slick-list {
  margin: -30px -15px; }

.review--s4.review--slider .slick-track {
  align-items: stretch; }

.review--s4.review--slider .slick-slide > div:first-child {
  display: flex;
  align-self: stretch; }

.review--s4.review--slider .slick-slide .review__item {
  opacity: 0.5; }

.review--s4.review--slider .slick-current .review__item {
  opacity: 1;
  transition-delay: 0s; }

@media (min-width: 992px) {
  .review--s4.review--slider .slick-current + .slick-active .review__item {
    opacity: 1;
    transition-delay: 0s; } }

@media (min-width: 1200px) {
  .review--s4.review--slider .slick-current + .slick-active + .slick-active .review__item {
    opacity: 1;
    transition-delay: 0s; } }

.review--s4.review--slider .review__item {
  transition: opacity 300ms cubic-bezier(0.43, 0.49, 0.51, 0.68) 600ms; }
  .review--s4.review--slider .review__item--shadow {
    margin: 30px 15px; }
    .review--s4.review--slider .review__item--shadow.review__item--corner-left, .review--s4.review--slider .review__item--shadow.review__item--corner-right {
      margin-bottom: 55px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .review--s4.review--slider .review__item {
      transition: none; } }

.review--s4 .review__item {
  background-color: #fff;
  padding: 30px 15px;
  font-size: 1.6rem; }
  .review--s4 .review__item--rounded {
    border-radius: 5px; }
  .review--s4 .review__item--shadow {
    box-shadow: 0px 0px 29px 0px rgba(174, 175, 175, 0.11); }
  .review--s4 .review__item--corner-left:after {
    left: 0;
    border-width: 25px 30px 0 0;
    border-color: #fff transparent transparent transparent; }
  .review--s4 .review__item--corner-left.review__item--rounded {
    border-bottom-left-radius: 0; }
  .review--s4 .review__item--corner-right:after {
    right: 0;
    border-width: 0 30px 25px 0;
    border-color: transparent #fff transparent transparent; }
  .review--s4 .review__item--corner-right.review__item--rounded {
    border-bottom-right-radius: 0; }
  .review--s4 .review__item--corner-left:after, .review--s4 .review__item--corner-right:after {
    content: "";
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    border-style: solid; }
  @media (min-width: 576px) {
    .review--s4 .review__item {
      padding: 30px; } }
  @media (min-width: 768px) {
    .review--s4 .review__item {
      padding: 30px 40px; } }

#blog .__item--rounded {
  border-radius: 20px; }

#blog .__image {
  border-radius: 20px; }

#blog .__content {
  padding: 45px 30px !important; }

#blog .__date-post {
  color: #1d2124 !important; }

/* --------------------------------
   screens app
-------------------------------- */
.screens-app .slick-slide {
  margin-left: 15px;
  margin-right: 15px; }

/* --------------------------------
   services
-------------------------------- */
.services .__item {
  position: relative;
  width: 100%; }

.services .__ico {
  display: inline-block;
  vertical-align: top;
  line-height: 1; }
  .services .__ico > img,
  .services .__ico > svg {
    display: block; }

.services .__image {
  position: relative;
  width: 100%;
  height: 0;
  margin: auto;
  overflow: hidden; }

.services--s1 .__inner {
  margin-bottom: -65px; }

.services--s1 .__item {
  margin-bottom: 65px;
  text-align: center; }

.services--s2 .col:nth-of-type(even):before {
  height: 30%;
  margin: auto;
  background: none !important; }

.services--s2 [class*=col-]:nth-of-type(even):before {
  height: 30%;
  margin: auto;
  background: none !important; }

.services--s2 {
  padding: 50px 15px;
  background: #fff;
  box-shadow: 0px 11px 21px 0px rgba(212, 212, 212, 0.26);
  border-radius: 20px; }
  .services--s2 .d-sm-table {
    display: inline-table !important; }
  .services--s2 .img-fluid {
    padding: 30px; }
  .services--s2 .__item {
    padding-left: 15px;
    padding-right: 15px; }
  .services--s2 .__inner {
    margin-bottom: -40px; }
  @media (min-width: 992px) {
    .services--s2 .col:nth-of-type(even):before,
    .services--s2 [class*=col-]:nth-of-type(even):before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0px;
      width: 1px;
      background: #eef4f9; }
    .services--s2 .col:nth-of-type(even) .__item,
    .services--s2 [class*=col-]:nth-of-type(even) .__item {
      margin-left: auto; } }
  .services--s2 .__item {
    margin-bottom: 40px; }
    @media (min-width: 992px) {
      .services--s2 .__item {
        max-width: 430px; } }
  .services--s2 .__ico {
    margin-bottom: 20px;
    margin-right: 20px; }
    @media (min-width: 992px) {
      .services--s2 .__ico {
        margin-bottom: 0; } }
  .services--s2 .__title {
    margin-bottom: 5px; }
  .services--s2 p {
    margin-top: 5px;
    margin-bottom: 5px; }

.services--s3 .__inner {
  margin-bottom: -50px; }

.services--s3 .__item {
  margin-bottom: 50px; }

.services--s4 .__inner {
  margin-bottom: -35px; }

.services--s4 .__item {
  margin-bottom: 35px; }

.services--s4 .__ico {
  display: inline-block;
  width: 70px;
  padding-right: 10px; }

.services--s5 .__inner {
  margin-bottom: -50px; }

.services--s5 .__item {
  margin-bottom: 50px; }

.services--s5 .__image {
  padding-top: 79.72973%;
  margin-bottom: 35px; }
  .services--s5 .__image--rounded {
    border-radius: 10px; }
  .services--s5 .__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
    font-family: "object-fit: cover; object-position: 50% 50%"; }

.services--s5 .__title {
  margin-bottom: 5px; }

.services--s5 .__more {
  font-size: 1.6rem;
  font-weight: 700; }

.services--s5 p {
  margin-top: 5px;
  margin-bottom: 5px; }

.services--s6 .__inner {
  margin-bottom: -50px; }

.services--s6 .__item {
  align-self: stretch;
  background-color: #fff;
  margin-bottom: 50px;
  padding: 30px 15px; }
  @media (min-width: 768px) {
    .services--s6 .__item {
      padding: 50px 30px; } }
  .services--s6 .__item--rounded {
    border-radius: 5px; }
  .services--s6 .__item--shadow {
    box-shadow: 0px 0px 29px 0px rgba(174, 175, 175, 0.11); }

.services--s6 .__title {
  margin-bottom: 15px; }

.services--s6 p {
  margin-top: 15px;
  margin-bottom: 15px; }

/* --------------------------------
   side menu
-------------------------------- */
.side-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 345px;
  background-color: #2d3a49;
  padding: 115px 30px 50px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translateX(100%);
  z-index: 6;
  transition: transform 400ms ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .side-menu {
      transition: none; } }
  .side-menu:before, .side-menu:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1; }
  .side-menu:before {
    background-image: url(../img/side-menu_bg-1.png);
    background-position: 50% 50%; }
  .side-menu.is-active {
    transform: translateX(0%); }
  .side-menu__button-close {
    position: absolute;
    top: 50px;
    right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: transform 200ms ease-in-out; }
    @media screen and (prefers-reduced-motion: reduce) {
      .side-menu__button-close {
        transition: none; } }
    .side-menu__button-close:before, .side-menu__button-close:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      margin-top: -1px;
      background-color: currentColor;
      transform-origin: 50% 50%; }
    .side-menu__button-close:before {
      transform: rotate(225deg); }
    .side-menu__button-close:after {
      transform: rotate(-225deg); }
    .side-menu__button-close:hover {
      transform: rotate(90deg); }
  .side-menu__inner {
    position: relative;
    width: 100%;
    max-width: 220px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: scrollbar; }
  .side-menu__menu {
    margin-top: 9.5vh;
    margin-bottom: 9.5vh;
    line-height: 1.2; }
    .side-menu__menu li {
      margin-top: 10px; }
      .side-menu__menu li:first-child {
        margin-top: 0; }
      .side-menu__menu li.active > a,
      .side-menu__menu li > a:hover,
      .side-menu__menu li > a:focus {
        color: #7c838b;
        text-decoration: underline; }
    .side-menu__menu a {
      color: inherit;
      text-decoration: none; }
  .side-menu__address {
    margin-top: 9.5vh;
    margin-bottom: 9.5vh;
    line-height: 1.875;
    font-style: normal; }
    .side-menu__address a {
      color: inherit; }
    .side-menu__address .social-btns a {
      font-size: 20px; }
  .side-menu .s-btns {
    margin-top: 30px; }

/* --------------------------------
   sidebar
-------------------------------- */
.sidebar {
  position: relative;
  min-height: 100%;
  transform: translateZ(0);
  z-index: 3; }

/* --------------------------------
   steps
-------------------------------- */
.steps {
  counter-reset: step-num; }
  .steps .__inner {
    margin-bottom: -40px; }
  .steps .__item {
    position: relative;
    width: 100%; }
    .steps .__item .__title {
      margin-bottom: 15px; }
    .steps .__item p {
      margin-top: 15px;
      margin-bottom: 15px; }

.steps--s1 .__item {
  margin-bottom: 40px;
  text-align: center; }
  .steps--s1 .__item .__num {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 120px;
    height: 120px;
    background-color: white;
    border: 2px solid #ddd;
    line-height: 116px;
    font-size: 4rem;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    color: #2368fb;
    border-radius: 50%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; }
    .steps--s1 .__item .__num:before {
      display: inline;
      counter-increment: step-num;
      content: counter(step-num, decimal-leading-zero); }
    .steps--s1 .__item .__num .__ico {
      position: absolute;
      top: -5px;
      right: -10px;
      width: 40px;
      height: 40px;
      background-color: #11c313;
      line-height: 40px;
      font-size: 1.8rem;
      color: #fff;
      border-radius: 50%; }

.steps--s2 .tab-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .steps--s2 .tab-nav {
      margin-bottom: 40px; } }
  @media (min-width: 992px) {
    .steps--s2 .tab-nav {
      margin-left: -20px; } }
  .steps--s2 .tab-nav__item {
    flex: 1 0 100%;
    margin-left: 15px;
    padding: 0;
    border-color: transparent;
    font-weight: 700;
    text-align: left;
    color: #333; }
    .steps--s2 .tab-nav__item.active {
      border-color: #2368fb;
      color: #2368fb; }
    @media (min-width: 576px) {
      .steps--s2 .tab-nav__item {
        flex: 1; } }
    @media (min-width: 992px) {
      .steps--s2 .tab-nav__item {
        margin-left: 20px; } }
  .steps--s2 .tab-nav__link {
    display: inline-block;
    padding: 10px 0;
    border-bottom: 2px solid;
    border-color: inherit;
    color: inherit; }
    .steps--s2 .tab-nav__link:before {
      display: inline;
      counter-increment: step-num;
      content: counter(step-num, decimal-leading-zero) ". "; }

.steps--s2 .__item {
  padding: 25px 15px;
  background-color: #fff; }
  @media (min-width: 576px) {
    .steps--s2 .__item {
      padding: 25px 30px; } }
  @media (min-width: 768px) {
    .steps--s2 .__item {
      padding: 30px 40px; } }
  @media (min-width: 992px) {
    .steps--s2 .__item {
      padding: 50px 60px; } }

.steps--s3 .__item {
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    .steps--s3 .__item {
      padding-left: 100px;
      padding-left: 27%; } }
  .steps--s3 .__item .__num {
    position: absolute;
    top: 95px;
    left: 4%;
    width: 1em;
    line-height: 1;
    font-size: 10rem;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    color: #f3f3f3;
    z-index: -1; }
    .steps--s3 .__item .__num:before {
      display: inline;
      counter-increment: step-num;
      content: counter(step-num, decimal-leading-zero); }

/* --------------------------------
   subscribe
-------------------------------- */
.subscribe-block {
  position: relative;
  padding: 40px 0;
  background: -moz-linear-gradient(0deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #6b5392), color-stop(18%, #6b5392), color-stop(60%, #1165b2), color-stop(100%, #00a4d4));
  background: -webkit-linear-gradient(0deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
  background: -o-linear-gradient(0deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
  background: -ms-linear-gradient(0deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
  background: linear-gradient(90deg, #6b5392 0%, #6b5392 18%, #1165b2 60%, #00a4d4 100%); }
  .subscribe-block--rounded {
    border-radius: 20px; }
  .subscribe-block:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: url(../img/subscribe-block_bg.svg) no-repeat center right 10%;
    background-size: 222px 222px; }

@media (min-width: 576px) {
  .subscribe-block {
    padding: 50px 0; }
    .subscribe-block--rounded {
      border-radius: 30px; } }

@media (min-width: 768px) {
  .subscribe-block {
    padding: 80px 0; } }

/* --------------------------------
   team
-------------------------------- */
.team--s1 .__soc-btns, .team--s2 .__soc-btns {
  margin-top: 20px;
  line-height: 1; }
  .team--s1 .__soc-btns a, .team--s2 .__soc-btns a {
    margin-left: 20px;
    font-size: 2rem;
    color: #3e3e3e; }
    .team--s1 .__soc-btns a:first-child, .team--s2 .__soc-btns a:first-child {
      margin-left: 0; }
    .team--s1 .__soc-btns a:hover, .team--s2 .__soc-btns a:hover, .team--s1 .__soc-btns a:focus, .team--s2 .__soc-btns a:focus {
      color: #aaa; }

.team .__inner {
  margin-bottom: -50px; }

.team .__item {
  position: relative;
  width: 250px;
  text-align: center;
  margin: auto; }

.team .__image {
  position: relative;
  margin: auto;
  overflow: hidden; }
  .team .__image img {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
    font-family: "object-fit: cover; object-position: 50% 50%"; }

.team .__content {
  padding-top: 25px;
  line-height: 1.2;
  color: #888; }

.team .__name {
  margin-bottom: 5px; }

.team .__position {
  font-size: 1.6rem; }

.team .__soc-btns a {
  display: inline-block;
  vertical-align: top;
  width: 1em;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .team .__soc-btns a {
      transition: none; } }

.team--s1 .__image {
  line-height: 200px; }

.team--s1 .__content {
  margin-top: -5px; }

.team--s2 .__item:hover .__image img {
  transform: scale(1.2) translateZ(0); }

.team--s2 .__image {
  height: 0;
  padding-top: 108.10811%; }
  .team--s2 .__image--rounded {
    border-radius: 5px; }
  .team--s2 .__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 200ms; }
    @media screen and (prefers-reduced-motion: reduce) {
      .team--s2 .__image img {
        transition: none; } }

.team--s3 .__image {
  display: inline-block;
  vertical-align: top;
  overflow: visible; }
  .team--s3 .__image img {
    border-radius: 50%; }

.team--s3 .__soc-btns a {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 1.5rem;
  color: #fff; }

.team--s3 .__soc-btns .fontello-linkedin {
  background-color: #0e76a8; }

.team--s3 .__soc-btns .fontello-facebook {
  background-color: #3b5998; }

.team--s3 .__soc-btns .fontello-gplus {
  background-color: #dd4b39; }

/* --------------------------------
   video
-------------------------------- */
.video-box {
  text-align: center; }
  .video-box .__image {
    position: relative;
    width: 100%;
    height: 0;
    min-height: 220px;
    margin: auto;
    overflow: hidden; }
    .video-box .__image--rounded {
      border-radius: 10px; }
      .video-box .__image--rounded .btn-play-link {
        border-radius: inherit; }
    .video-box .__image img,
    .video-box .__image .btn-play-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .video-box .__image img {
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: 50% 50%;
      object-position: 50% 50%;
      font-family: "object-fit: cover; object-position: 50% 50%"; }
    .video-box .__image .btn-play {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto; }

.video-box--s2 .__image {
  padding-top: 66.88312%; }

.btn-play-link {
  display: inline-block;
  vertical-align: top; }
  .btn-play-link:hover .btn-play {
    background-color: #2d3a49; }

.btn-play {
  position: relative;
  display: block;
  width: 80px;
  height: 80px;
  background-color: #2368fb;
  border-radius: 20px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn-play {
      transition: none; } }
  .btn-play:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 7px;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 0 12px 15px;
    border-color: transparent transparent transparent #fff; }

#usecases {
  background-color: #f9fbfc; }

.usecase-item .__item {
  max-width: 100% !important; }

.usecase .__item {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important; }
  .usecase .__item a {
    text-decoration: none !important;
    color: #1d2124 !important; }

.usecase .__title {
  padding-top: 25px !important;
  padding-left: 25px !important;
  padding-right: 25px !important; }

.usecase .__content {
  padding-left: 25px;
  padding-right: 25px; }

#solution {
  background-image: url("../img/oneiot/bg-solution.png");
  background-repeat: no-repeat;
  background-color: #f9fbfc;
  background-size: cover; }
  #solution .space-resp {
    margin-top: 80px; }
  #solution .__item {
    padding-left: 45px;
    padding-right: 45px; }
  @media (min-width: 576px) {
    #solution .__item {
      min-height: 0; } }
  @media (min-width: 768px) {
    #solution .__item {
      min-height: 100px !important; }
    #solution .space-resp {
      margin-top: 80px; } }
  @media (min-width: 992px) {
    #solution .__item {
      min-height: 366px !important; }
    #solution .space-resp {
      margin-top: 0px; } }

.custom-solution {
  width: 85%; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }

.alert {
  font-size: 1.6rem;
  position: relative;
  padding: 1.5rem 3.0rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 3.0rem; }

.close:not(:disabled):not(.disabled) {
  cursor: pointer; }

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .75rem 1.25rem;
  color: inherit; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.close {
  float: right;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  margin-left: 30px; }
