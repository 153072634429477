/* --------------------------------
   comments list
-------------------------------- */

$gutter: 30px;
$img-width: 70px;

.comments-list {
  margin-top: $gutter;
  margin-bottom: $gutter;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  > .comment {
    &:first-child {
      margin-top: 0;
    }
  }

  .comment {
    margin-top: 50px;
    font-size: 1.6rem;

    &__author-img {
      width: $img-width;
      margin-right: 20px;
      overflow: hidden;
      border-radius: 50%;
    }

    &__author-name {
      display: block;
      line-height: 1;
      font-size: 1.6rem;
      font-family: $fontFamily-secondary;
      font-weight: 700;
      color: #333;
    }

    &__reply {

    }
  }

  ul {
    padding-left: $gutter;
  }
}

@include media-breakpoint-up(sm) {
  .comments-list {

  }
}

@include media-breakpoint-up(md) {
  .comments-list {

  }
}

@include media-breakpoint-up(lg) {
  .comments-list {
    ul {
      padding-left: $img-width - 20px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .comments-list {
    ul {
      padding-left: $img-width + 20px;
    }
  }
}
