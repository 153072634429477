/* --------------------------------
   screens app
-------------------------------- */

.screens-app {
  .slick-slide {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}
