/* --------------------------------
   footer
-------------------------------- */

.footer {
  position: relative;
  font-size: 1.6rem;

  &__line {
    position: relative;
  }

  &__item {
    position: relative;
    width: 100%;
    float: left;
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 2;
  }

  &__navigation {
    line-height: 1.4;
    font-weight: 700;

    &:first-child {
      margin-top: -10px;
    }

    ul {
    }

    li {
      margin-top: 10px;

      &.active {
        a {
          text-decoration: underline;
        }
      }
    }

    a {
      color: $branding-black-dark;
      text-decoration: none;
    }
  }

  &__address {
    font-style: normal;

    &--s1 {
      line-height: 1.8;
      font-weight: 700;
    }

    &--s2 {
      line-height: 1.6;
      font-weight: 700;

      ul {

      }

      li {
        margin-top: 15px;
        padding-left: 35px;

        &:first-child {
          margin-top: 0 !important;
        }

        &:after {
          content: "";
          display: table;
          clear: left;
        }
      }

      .__ico {
        float: left;
        margin-left: -35px;
        width: 1em;
        line-height: 1;
        font-size: 2.3rem;

        &:before {
          vertical-align: top;
        }
      }
    }

    &--s3 {
      line-height: 1.2;
    }

    p {
      margin-top: 10px;
      margin-bottom: 10px;

      &:first-child {
        margin-top: 0 !important;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    a {
      color: inherit;
    }
  }

  &__wave {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .__copy {
    font-size: 1.4rem;
  }

  .__dev {
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.footer__item {
  .textfield {
    color: $branding-black-light;
    background-clip: unset !important;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: red;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: red;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: red;
  }
}

.footer--color-light {
  color: $branding-black-dark;

  .footer__item__title {
    color: inherit;
  }

  .footer__navigation {
    li {
      footer__navigation
      &.active {
        a {
          color: $branding-black-dark;
        }
      }
    }

    a {
      &:hover {
        color: $primary-color;
      }
    }
  }

  .footer__address {
    &--s3 {
      strong {
      }
    }
  }
}

.footer--color-dark {
  color: $branding-black-dark;

  .footer__navigation {
    i {
      margin-right: 10px !important;
      font-size: 10px;
    }

    li {
      &.active {
        a {
          color: $branding-black-dark;
        }
      }
    }

    a {
      &:hover {
        color: $primary-color;
      }
    }
  }

  .footer__address {
    &--s3 {
      strong {
        color: #333;
      }
    }
  }
}

.footer--s3 {
  .footer__line {
    color: $branding-black-dark;

    &--first {
      background-color: #fff;
      padding-top: 70px - 20px;
      padding-bottom: 90px - 20px;
    }

    &--second {
      background-color: #fff;
      padding-top: 30px - 20px;
      padding-bottom: 30px - 20px;
    }
  }

  .__copy {
    color: $branding-black-dark;
    font-size: small;

    a {
      color: $branding-black-dark !important;
      text-decoration: none !important;
    }

    a:hover {
      color: $branding-black-dark !important;
      text-decoration: none !important;
    }
  }

  .footer__wave {
    height: 150px;
  }
}

.footer__navigation {
  a {
    font-weight: 500;
  }
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}
