/* --------------------------------
   brands list
-------------------------------- */

$marginBottom: 30px;

.brands-list {
  .__inner {
    margin-bottom: -$marginBottom;
  }

  .__item {
    position: relative;
    width: 100%;
    margin-bottom: $marginBottom;
    text-align: center;
  }

  .__image {
    margin: auto;
    opacity: 0.2;
    @include transition(opacity 0.5s ease-in-out);

    &:hover {
      opacity: 0.8;
    }
  }
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}
