/* --------------------------------
   subscribe
-------------------------------- */

.subscribe-block {
  position: relative;
  padding: 40px 0;
  background: -moz-linear-gradient(0deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(107, 83, 146, 1)), color-stop(18%, rgba(107, 83, 146, 1)), color-stop(60%, rgba(17, 101, 178, 1)), color-stop(100%, rgba(0, 164, 212, 1)));
  background: -webkit-linear-gradient(0deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);
  background: -o-linear-gradient(0deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);
  background: -ms-linear-gradient(0deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);
  background: linear-gradient(90deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);

  &--rounded {
    border-radius: 20px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: url(../img/subscribe-block_bg.svg) no-repeat center right 10%;
    background-size: 222px 222px;
  }
}

@include media-breakpoint-up(sm) {
  .subscribe-block {
    padding: 50px 0;

    &--rounded {
      border-radius: 30px;
    }
  }
}

@include media-breakpoint-up(md) {
  .subscribe-block {
    padding: 80px 0;
  }
}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}
