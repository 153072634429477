/* --------------------------------
   projects
-------------------------------- */

.projects {
  .__inner {
  }

  .__item {
    position: relative;
    align-self: stretch;
    width: 100%;
  }

  .__image {
    position: relative;
    width: 100%;
    height: 0;
    margin: auto;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: auto;
      @include object-fit(cover, 50% 50%);
    }
  }

  .__filter {
    margin-left: -30px;
    margin-bottom: 40px;
    line-height: 0;
    font-size: 0;
    letter-spacing: -1px;

    li {
      display: inline-block;
      vertical-align: middle;
      margin-left: 30px;
      margin-bottom: 20px;
      letter-spacing: 0;
    }

    a {
      padding-bottom: 5px;
      border-bottom: 2px solid transparent;
      line-height: 1.2;
      font-size: 1.6rem;
      font-weight: 700;
      text-decoration: none;
      color: #333;

      &:hover,
      &.selected {
        color: #a3a3a3;
      }

      &.selected {
        border-color: $primary-color;
      }
    }
  }
}

.projects--s1 {
  $marginBottom: 50px;

  .__inner {
    margin-bottom: -$marginBottom;
  }

  .__item {
    margin-bottom: $marginBottom;
    padding: 50px 15px;
    background-color: $white;
    text-align: center;
    @include transition(box-shadow 0.3s ease-in-out);

    &--shadow {
      box-shadow: 0px 0px 68px 0px rgba(#aeafaf, 0.17);

      &:hover {
        box-shadow: 0px 0px 68px 0px rgba(#5a5a5a, 0.54);
      }
    }

    > div {
      width: 100%;
    }

    .__header,
    .__body {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-up(sm) {
      display: flex;
      flex-direction: column;
      align-items: center;

      .__header {
        flex: 0 0 auto;
      }

      .__body {
        flex: 1 0 auto;
      }

      .__footer {
        flex: 0 0 auto;
      }
    }
  }

  .__image {
    min-height: 200px;
    padding-top: percentage(230/300);

    img {
      right: 0;
      bottom: 0;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.projects--s2 {
  &-a {
    $marginBottom: 30px;

    .__inner {
      margin-bottom: -$marginBottom;
    }

    .__item {
      margin-bottom: $marginBottom;

      @include media-breakpoint-up(sm) {
        &[data-x="2"][data-y="1"] .__image {
          padding-top: -webkit-calc(50% - 15px);
          padding-top: -moz-calc(50% - 15px);
          padding-top: calc(50% - 15px);
        }

        &[data-x="1"][data-y="2"] .__image {
          padding-top: -webkit-calc(200% + 30px);
          padding-top: -moz-calc(200% + 30px);
          padding-top: calc(200% + 30px);
        }
      }
    }
  }

  &-b {
    .__item {
      @include media-breakpoint-up(sm) {
        &[data-x="2"][data-y="1"] .__image {
          padding-top: 50%;
        }

        &[data-x="1"][data-y="2"] .__image {
          padding-top: 200%;
        }
      }
    }
  }

  .__item {
    .__image {
      padding-top: 100%;
    }

    &:hover {
      .__content {
        opacity: 1;
      }
    }
  }

  .__image {
  }

  .__content {
    $bgColor: lighten(desaturate(adjust-hue($primary-color, 19), 24.01), 2.94);

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: rgba($bgColor, 0.75);
    opacity: 0;
    text-align: center;
    color: $white;

    @include transition(
                    background-color 0.3s ease-in-out,
                    opacity 0.3s ease-in-out
    );
  }

  .__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    + .__title {
      margin-top: 0;
    }
  }

  .__title {
    color: inherit;
  }
}

.projects--s3 {
  &-a {
    $marginBottom: 30px;

    .__inner {
      margin-bottom: -$marginBottom;
    }

    .__item {
      margin-bottom: $marginBottom;

      @include media-breakpoint-up(sm) {
        &[data-x="2"][data-y="1"] .__image {
          padding-top: -webkit-calc(50% - 15px);
          padding-top: -moz-calc(50% - 15px);
          padding-top: calc(50% - 15px);
        }

        &[data-x="1"][data-y="2"] .__image {
          padding-top: -webkit-calc(200% + 30px);
          padding-top: -moz-calc(200% + 30px);
          padding-top: calc(200% + 30px);
        }
      }
    }
  }

  &-b {
    .__item {
      @include media-breakpoint-up(sm) {
        &[data-x="2"][data-y="1"] .__image {
          padding-top: 50%;
        }

        &[data-x="1"][data-y="2"] .__image {
          padding-top: 200%;
        }
      }
    }
  }

  .__item {
    .__image {
      padding-top: 100%;
    }

    &:hover {
      $bgColor: lighten(desaturate(adjust-hue($primary-color, 4), 11.23), 0.78);

      .__content {
        background-color: rgba($bgColor, 0.75);
      }
    }
  }

  .__image {
  }

  .__content {
    display: flex;
    align-items: flex-end;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px 15px;
    color: $white;

    @include transition(
                    background-color 0.3s ease-in-out,
                    opacity 0.3s ease-in-out
    );

    @include media-breakpoint-up(sm) {
      padding: 30px;
    }
  }

  .__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    + .__title {
      margin-top: 0;
    }
  }

  .__title {
    color: inherit;
  }
}

.projects--s4 {
  .slick-list {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .slick-slide {
    padding-left: 15px;
    padding-right: 15px;

    .__item {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  .__item {
    @include transition(box-shadow 0.3s ease-in-out);

    &--shadow {
      box-shadow: 0px 0px 40px 0px rgba(#aeafaf, 0.17);
    }
  }

  .__image {
    min-height: 200px;
    padding-top: 100%;
  }

  .__content {
    padding: 25px 15px 50px;
    background-color: $white;
    text-align: center;

    @include media-breakpoint-up(md) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}
