#brands {
  padding-top: 0 !important;
  background-color: #2368fb;
}

#testimonials {
  padding-bottom: 90px !important;
  background-color: #2368fb;
}

.reviewer-company {
  font-weight: bold;
  font-size: 14px;
}

#brands-small {
  background-color: #f3f3f3;
}
