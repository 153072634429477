.header-progress-bar {
  position: fixed;
  top: 105px;
  z-index: 1;
  width: 100%;
  background-color: #f1f1f1;
}

.header-progress-bar h2 {
  text-align: center;
}

.progress-container {
  width: 100%;
  height: 8px;
  background: #ccc;
}

.progress-bar {
  height: 8px;
  background: $primary-color;
  width: 0%;
}
