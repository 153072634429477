#usecases {
  background-color: $branding-white-mid;
}

.usecase-item {
  .__item {
    max-width: 100% !important;
  }
}

.usecase {

  .__img {

  }

  .__item {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;

    a {
      text-decoration: none !important;
      color: $text-color-dark !important;
    }
  }

  .__title {
    padding-top: 25px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .__content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

