/* --------------------------------
   content
-------------------------------- */

.content-container {
  .demo-selection {
    background-color: $primary-color;
    color: $white;
  }

  ol:not([class]),
  ul:not([class]) {
    line-height: 1.2;
    margin-top: 25px;
    margin-bottom: 25px;

    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }

    li {
      margin-top: 15px;
      padding-left: 15px;

      &:before {
        float: left;
        margin-left: -15px;
        margin-right: 5px;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  ol:not([class]) {
    counter-reset: num;

    li {
      &:before {
        counter-increment: num;
        content: counter(num) ".";
        font-weight: 700;
        color: $primary-color;
      }
    }
  }

  ul:not([class]) {
    li {
      &:before {
        content: "";
        width: 0;
        height: 0;
        margin-top: 7px;
        border: 3px solid $primary-color;
        border-radius: 50%;
      }
    }
  }

  hr {
    margin-top: 60px;
    margin-bottom: 60px;
    border: none;
    border-top: 1px solid #ebebeb;

    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  .blockquot {
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 20px;
    border-left: 4px solid $primary-color;
    line-height: 1.4;
    font-size: 2rem;
    color: #333;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      padding-left: 30px;
      line-height: 1.8;
    }
  }

  .dropcaps {
    .first-letter {
      float: left;
      margin-right: 10px;
      line-height: 0.9;
      font-size: rem-calc(46px);
      font-weight: 800;
      color: $primary-color;
    }
  }
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}
