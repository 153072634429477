#dashboard-company {
  background-color: #f3f3f3 !important;
}

#dashboard-customer {
  background-color: #fff !important;
}

#dashboard-features {
  background-color: #f3f3f3 !important;
}
