/* --------------------------------
   share buttons
-------------------------------- */

$space: 5px;

.share-btns {
  &__list {
    margin: -$space;
    line-height: 0;
    font-size: 0;
    letter-spacing: -1px;
  }

  li {
    display: inline-block;
    vertical-align: middle;
    margin: $space;
    line-height: 1.1;
  }

  a {
    display: block;
    padding: 12px 25px;
    font-size: 1.8rem;
    color: $white;
    text-decoration: none;
    letter-spacing: 0;
    border-radius: 30px;
    @include transition(
                    background-color 0.25s ease-in-out,
                    border-color 0.25s ease-in-out,
                    color 0.25s ease-in-out
    );
  }

  .fb {
    background-color: #3c5a9a;

    &:hover,
    &:focus {
      background-color: darken(#3c5a9a, 8%);
    }
  }

  .tw {
    background-color: #1da1f2;

    &:hover,
    &:focus {
      background-color: darken(#1da1f2, 8%);
    }
  }

  .yt {
    background-color: #f11819;

    &:hover,
    &:focus {
      background-color: darken(#f11819, 8%);
    }
  }

  .in {
    background-image: -moz-linear-gradient(155deg, #f4a961 13%, #c32869 54%, #7324c1 100%);
    background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #7324c1), color-stop(46%, #c32869), color-stop(87%, #f4a961));
    background-image: -webkit-linear-gradient(155deg, #f4a961 13%, #c32869 54%, #7324c1 100%);
    background-image: -o-linear-gradient(155deg, #f4a961 13%, #c32869 54%, #7324c1 100%);
    background-image: -ms-linear-gradient(155deg, #f4a961 13%, #c32869 54%, #7324c1 100%);
    background-image: linear-gradient(295deg, #f4a961 13%, #c32869 54%, #7324c1 100%);
  }

  [class*=" fontello-"],
  [class^=fontello-] {
    display: inline-block;
    width: 1em;
    margin-right: 10px;
  }
}

@include min-screen($sm-width) {

}

@include min-screen($md-width) {

}

@include min-screen($lg-width) {

}

@include min-screen($xl-width) {

}
