.cookiealert {
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 !important;
  z-index: 999;
  opacity: 0;
  background: $branding-white-dark;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  color: $branding-black-dark;
  border-radius: 0 !important;
  border: none !important;
  box-shadow: 6px 6px 6px 6px rgba(0,0,0,.2);

  .btn {
    padding: 0.5rem 4.6rem !important;
    font-size: 1.64rem !important;
    border-radius: 20px !important;
    background-color: $primary-color !important;
    margin-top: 15px;
    text-transform: none !important;
  }
}

.cookie-alert-col {
  margin: auto;
}

.cookiealert.show {
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 1000ms;
}

.cookiealert a {
  text-decoration: underline
}

.cookiealert .acceptcookies {
  margin-left: 10px;
  vertical-align: baseline;
}
