/* --------------------------------
   pricing table
-------------------------------- */

$marginBottom: 50px;
$paddingX: 15px;
$paddingY: 60px;
$itemBorderWidth: 4px;

.pricing-card {
  display: block !important;
}

.pricing-table {
  .__inner {
    margin-bottom: -$marginBottom;
  }

  .__header {

  }

  .__info {
    bottom: 28px !important;
    font-size: 2rem !important;
    font-weight: 400 !important;
  }

  .__item--best--choice {
    border: 3px solid $primary-color !important;
    box-shadow: 7px 7px rgba(172, 214, 97, 0.2) !important;
  }

  .__item {
    position: relative;
    width: 100%;
    background-color: $white;
    margin-bottom: $marginBottom;
    padding: $paddingY $paddingX;
    text-align: center;
    overflow: hidden;

    &--rounded {
      border-radius: 5px;
    }

    &--bordered {
      padding: ($paddingY - $itemBorderWidth) ($paddingX - $itemBorderWidth);
      border: $itemBorderWidth solid;
    }

    @include media-breakpoint-up(sm) {
      display: flex;
      flex-direction: column;
      align-items: center;

      .__header {
        flex: 0 0 auto;
      }

      .__body {
        flex: 1 0 auto;
      }

      .__footer {
        flex: 0 0 auto;
      }
    }
  }

  .__label {
    position: absolute;
    line-height: 1.3;
    font-size: 1.3rem;
    font-weight: 700;
    text-transform: uppercase;
    @include userSelect(none);

    + .__title {
      margin-top: 0;
    }
  }

  .__price {
    margin-top: 25px;
    margin-bottom: 25px;
    line-height: 1;
    font-size: 6rem;
    font-weight: 700;
    font-family: $fontFamily-secondary;
    letter-spacing: -3px;
    color: #333;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    sup {
      font-size: 3.5rem;
    }

    sub {
      bottom: auto;
      font-size: 3rem;
      letter-spacing: -1px;
    }
  }

  .__desc-list {
    line-height: 1.5;
    line-height: 1.2;
    font-size: 1.6rem;

    li {
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.pricing-table--s1 {
  .__item {
    &--shadow {
      box-shadow: 0px 0px 29px 0px rgba(#aeafaf, 0.11);
    }

    &--active {
      background-color: $primary-color;
      color: $white;

      .__title,
      .__price,
      .__value {
        color: inherit;
      }

      // .custom-btn
      // {
      // 	background-color: $white;
      // 	border-color: $white;
      // 	color: #333;

      // 	&:hover
      // 	{
      // 		background-color: transparent;
      // 		color: $white;
      // 	}
      // }

      .__label {
        top: 4px;
        right: 4px;
        background-color: $white;
        padding: 7px 20px;
        color: #01a1d2;
      }
    }
  }

  .disabled {
    color: #c5c5c5;
  }
}

.pricing-table--s2 {
  $space: 20px;

  .__item {
    &--shadow {
      box-shadow: 0px 0px 29px 0px rgba(#aeafaf, 0.11);
    }

    &--active {
      .__label {
        top: 0;
        right: 0;
        background-color: #e3306f;
        padding: 12px 25px;
        color: $white;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: $marginBottom - $space;
        padding-bottom: $paddingY + $space;

        .__header {
          padding-bottom: $space;
        }
      }
    }

    &:not(.__item--active) {
      @include media-breakpoint-up(md) {

      }
    }
  }
}

.pricing-table--s3 {
  $color1: #ff5252;
  $color2: #26b251;
  $color3: #255da9;
  $color4: #ffb042;

  .__item {
    &--color-1 {
      border-color: $color1;

      .__price {
        color: $color1;
      }

      .custom-btn {
        &:before {
          background: -moz-linear-gradient(0deg, rgba(246, 48, 104, 1) 0%, rgba(250, 110, 61, 1) 100%);
          background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(246, 48, 104, 1)), color-stop(100%, rgba(250, 110, 61, 1)));
          background: -webkit-linear-gradient(0deg, rgba(246, 48, 104, 1) 0%, rgba(250, 110, 61, 1) 100%);
          background: -o-linear-gradient(0deg, rgba(246, 48, 104, 1) 0%, rgba(250, 110, 61, 1) 100%);
          background: -ms-linear-gradient(0deg, rgba(246, 48, 104, 1) 0%, rgba(250, 110, 61, 1) 100%);
          background: linear-gradient(90deg, rgba(246, 48, 104, 1) 0%, rgba(250, 110, 61, 1) 100%);
        }
      }
    }

    &--color-2 {
      border-color: $color2;

      .__price {
        color: $color2;
      }

      .custom-btn {
        &:before {
          background: -moz-linear-gradient(0deg, rgba(47, 183, 107, 1) 0%, rgba(138, 200, 75, 1) 100%);
          background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(47, 183, 107, 1)), color-stop(100%, rgba(138, 200, 75, 1)));
          background: -webkit-linear-gradient(0deg, rgba(47, 183, 107, 1) 0%, rgba(138, 200, 75, 1) 100%);
          background: -o-linear-gradient(0deg, rgba(47, 183, 107, 1) 0%, rgba(138, 200, 75, 1) 100%);
          background: -ms-linear-gradient(0deg, rgba(47, 183, 107, 1) 0%, rgba(138, 200, 75, 1) 100%);
          background: linear-gradient(90deg, rgba(47, 183, 107, 1) 0%, rgba(138, 200, 75, 1) 100%);
        }
      }
    }

    &--color-3 {
      border-color: $color3;

      .__price {
        color: $color3;
      }

      .custom-btn {
        &:before {
          background: -moz-linear-gradient(0deg, rgba(37, 93, 169, 1) 0%, rgba(0, 164, 212, 1) 100%);
          background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(37, 93, 169, 1)), color-stop(100%, rgba(0, 164, 212, 1)));
          background: -webkit-linear-gradient(0deg, rgba(37, 93, 169, 1) 0%, rgba(0, 164, 212, 1) 100%);
          background: -o-linear-gradient(0deg, rgba(37, 93, 169, 1) 0%, rgba(0, 164, 212, 1) 100%);
          background: -ms-linear-gradient(0deg, rgba(37, 93, 169, 1) 0%, rgba(0, 164, 212, 1) 100%);
          background: linear-gradient(90deg, rgba(37, 93, 169, 1) 0%, rgba(0, 164, 212, 1) 100%);
        }
      }
    }

    &--color-4 {
      border-color: $color4;

      .__price {
        color: $color4;
      }

      .custom-btn {
        &:before {
          background: -moz-linear-gradient(0deg, rgba(251, 190, 0, 1) 0%, rgba(253, 217, 104, 1) 100%);
          background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(251, 190, 0, 1)), color-stop(100%, rgba(253, 217, 104, 1)));
          background: -webkit-linear-gradient(0deg, rgba(251, 190, 0, 1) 0%, rgba(253, 217, 104, 1) 100%);
          background: -o-linear-gradient(0deg, rgba(251, 190, 0, 1) 0%, rgba(253, 217, 104, 1) 100%);
          background: -ms-linear-gradient(0deg, rgba(251, 190, 0, 1) 0%, rgba(253, 217, 104, 1) 100%);
          background: linear-gradient(90deg, rgba(251, 190, 0, 1) 0%, rgba(253, 217, 104, 1) 100%);
        }
      }
    }

    &--active {
      background: -moz-linear-gradient(90deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 164, 212, 1)), color-stop(40%, rgba(17, 101, 178, 1)), color-stop(82%, rgba(107, 83, 146, 1)), color-stop(100%, rgba(107, 83, 146, 1)));
      background: -webkit-linear-gradient(90deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);
      background: -o-linear-gradient(90deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);
      background: -ms-linear-gradient(90deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);
      background: linear-gradient(0deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);
      color: $white;

      .__title,
      .__price,
      .__value {
        color: inherit;
      }

      .__label {
        top: 4px;
        right: 4px;
        background-color: $white;
        padding: 7px 20px;
        color: #01a1d2;
      }

      .custom-btn {
        color: #333;

        &:hover,
        &:focus {
          background-color: #2d3a49;
          border-color: #2d3a49;
          color: $white;
        }
      }
    }

    &:not(.__item--active) {
      .custom-btn {
        background-color: #2d3a49;
        border-color: #2d3a49;

        &:hover,
        &:focus {
          &:before {
            opacity: 0;
          }
        }
      }
    }
  }

  .__value {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    font-family: $fontFamily-secondary;
    color: #333;
  }

  .custom-btn {
    background-color: $white;
    border-color: $white;
    color: $white;

    &:before {
      content: "";
    }
  }
}

.pricing-table--s4 {
  table {
    background-color: $white;
    font-size: 1.6rem;

    &.rounded {
      border-radius: 5px;
    }

    &.shadow {
      box-shadow: 0px 0px 29px 0px rgba(#aeafaf, 0.11);
    }

    .__price {
      font-size: 2rem;
      letter-spacing: -2px;

      sup,
      sub {
        font-size: inherit;
      }

      sup {
        top: auto;
      }
    }
  }

  thead {
  }

  tbody {
    tr {
      &:nth-of-type(2n) {
        background-color: #f7f7f7;

        td {
          &.active {
            background-color: rgba($primary-color, 0.8);
          }
        }
      }
    }

    td {
      height: 62px;
    }

    th {
      height: 90px;
    }
  }

  tfoot {
    tr {
    }

    td {
      padding-top: 35px;
      padding-bottom: 45px;
    }
  }

  tr {
  }

  th,
  td {
    &:first-child {
      width: 25%;
      min-width: 250px;
      padding-left: 4%;
      text-align: left;
    }

    &.active {
      background-color: $primary-color;
      color: $white;

      .__title,
      .__price {
        color: inherit;
      }
    }
  }

  td {
    padding-left: 10px;
    padding-right: 10px;
  }


  .__item {
    &--shadow {
      box-shadow: 0px 0px 29px 0px rgba(#aeafaf, 0.11);
    }

    &--active {
      background: -moz-linear-gradient(90deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 164, 212, 1)), color-stop(40%, rgba(17, 101, 178, 1)), color-stop(82%, rgba(107, 83, 146, 1)), color-stop(100%, rgba(107, 83, 146, 1)));
      background: -webkit-linear-gradient(90deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);
      background: -o-linear-gradient(90deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);
      background: -ms-linear-gradient(90deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);
      background: linear-gradient(0deg, rgba(107, 83, 146, 1) 0%, rgba(107, 83, 146, 1) 18%, rgba(17, 101, 178, 1) 60%, rgba(0, 164, 212, 1) 100%);
      color: $white;

      .__title,
      .__price,
      .__name,
      .__value {
        color: inherit;
      }

      .__label {
        top: 4px;
        right: 4px;
        background-color: $white;
        padding: 7px 20px;
        color: #01a1d2;
      }

      .__desc-list {
        li {
          &:nth-of-type(2n+1) {
            background: rgba($white, 0.15);
          }
        }
      }
    }
  }

  .__body {
    width: 100%;
  }

  .__name {
    margin-bottom: 10px;
  }

  .__desc-list {
    margin-left: -15px;
    margin-right: -15px;

    li {
      margin: 0;
      padding: 10px 15px;

      &:nth-of-type(2n+1) {
        background-color: #f7f7f7;
      }

      span {
        &:first-child {
          float: right;
        }
      }
    }
  }
}

.pricing-table--s5 {
  .__item {
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
  }

  .__body {
    text-align: center;
  }

  .__title,
  .__price,
  .__desc-list {
    color: $white;
  }

  .__desc-list {
    display: inline-block;
    vertical-align: top;
  }

  .custom-btn {
    color: $white;
    border-color: $white;

    &:hover,
    &:focus {
      background-color: $white;
      color: #333;
    }
  }
}

.pricing-info {
  font-size: 14px;
}

.platinum-storage .__price {
  letter-spacing: 0px !important;
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}
