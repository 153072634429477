#hardware-connectivity {
  .__title {
    color: #2a2c2f;
  }
}

#hardware-features {
  background-color: #f3f3f3!important;
}

#hardware-why {
  background-color: #f3f3f3!important;
}
