/* --------------------------------
   custom slick styles
-------------------------------- */

.slick-slider {
}

.slick-track {
  display: flex;
  align-items: flex-start;
}

.slick-slide {
  flex-shrink: 0;
  outline: none;

  > div:first-child {
    flex: 0 0 100%;
    width: 100%;
    height: 100%;
  }

  .slick-initialized & {
    display: flex;
    height: auto;
  }
}

.slick-arrow {
  line-height: 1;
  text-align: center;
  cursor: pointer;
  z-index: 1;

  &.slick-disabled {
    cursor: default;
  }
}

.slick-dots {
  line-height: 0;
  font-size: 0;

  &:last-child {
    margin-top: 45px;
  }

  li {
    position: relative;
    display: inline-block;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }

    &.slick-active {
      button {
        color: darken(desaturate(adjust-hue($primary-color, 5), 18.41), 4.12);
        border-color: currentColor;
        cursor: default;

        &:before {
          top: 3px;
          right: 3px;
          bottom: 3px;
          left: 3px;
          background-color: currentColor;
        }
      }
    }
  }

  button {
    position: relative;
    display: block;
    width: 15px;
    height: 15px;
    padding: 0;
    cursor: pointer;
    cursor: hand;
    color: transparent;
    border: 2px solid transparent;
    outline: none;
    background: transparent;
    @include border-radius(50%);

    &:before {
      content: "";
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      background-color: #e7eff7;
      border-radius: inherit;
    }
  }

  &--white {
    li {
      &.slick-active {
        button {
          color: $white;
        }
      }
    }
  }
}
