.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert {
  font-size: 1.6rem;
  position: relative;
  padding: 1.5rem 3.0rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 3.0rem;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .75rem 1.25rem;
  color: inherit;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.close {
  float: right;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  margin-left: 30px;
}
