#blog {
  .__item--rounded {
    border-radius: 20px;
  }

  .__image {
    border-radius: 20px;
  }

  .__content {
    padding: 45px 30px !important;
  }

  .__date-post {
    color: #1d2124 !important;
  }
}
