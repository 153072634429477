#contact-eyecatcher {
  button {
    border-color: #1d2124 !important;
    background-color: #1d2124 !important;
    color: #fff !important;
  }

  button:hover {
    border-color: #2a2c2f !important;
    background-color: #2a2c2f !important;
  }
}
