#contact {
  background-color: $branding-black-dark;

  .__title {
    color: $white;
  }

  .slogan-txt {
    color: $white;
    font-size: 19px;
  }

  .__inner {
    color: $white;
  }
}
